
import axios from "axios";
import { useEffect, useState,useContext,useRef } from "react";
import { DatePicker, Spin, Tag, Button, Form, Input, Popconfirm, Table, FormInstance, InputRef } from 'antd';

import Mailto from "../../link/mailto";
import Tel from "../../link/tel";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { MeetMeta } from "../../../models/recruitment/meet/meetMeta";
import { FiEdit2, FiPlus } from "react-icons/fi";
import { AiOutlineCheck, AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import CustomInput from "../../form/customInput";
import React from "react";
import ReminderManager from "../../notification/reminderManager/reminderManager";
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const getPopupContainer = (trigger: any) => {
  // Bu mantığı, spesifik gereksinimlerinize uygun olarak düzenleyebilirsiniz
  return trigger.parentElement || document.body;
};

const EditableRow: React.FC<{ index: number }> = ({ index, ...props }) => {

  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};


const EditableCell: React.FC<{
  title: string;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Record<string, any>;
  handleSave: (record: Record<string, any>) => void;
}> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {

  const [editing, setEditing] = useState(false);
    
  const inputRef = useRef<HTMLInputElement | null>(null);
  const form = useContext(EditableContext);
  
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus(); // Optional chaining ekledik
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form?.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form?.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Kayıt hatası:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} alanı zorunludur.`,
          },
        ]}
      >
        {dataIndex === 'meet_date_time' ? (
          
          <input
          className="form-control"
          type="datetime-local"
          ref={inputRef}
          onChange={(e) => form?.setFieldsValue({ [dataIndex]: moment(e.target.value).format('YYYY-MM-DD HH:mm') })}
          onBlur={() => save()}
          defaultValue={moment(record[dataIndex]).format("DD.MM.YYYY HH:mm")}
          value={moment(record[dataIndex]).format("DD.MM.YYYY HH:mm")}
        />
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const MeetDetail = ({ id }: any) => {

  const [update_meet_meta, setUpdateMeetMeta] = useState(true);
  const [meet, setMeet] = useState<any | null>(null);
  const [meetMeta, setMeetMeta] = useState<MeetMeta[]>([]);
  const [newMeetDetails, setNewMeetDetails] = useState<MeetMeta[]>([]);

  const handleAddMeet = () => {
    setNewMeetDetails([...newMeetDetails, { id: newMeetDetails.length + 1, meet_date_time: new Date(), name: '', definition: '' }]);
  };

  const handleRemoveMeet = (index: number) => {
    setNewMeetDetails(newMeetDetails.filter((_, i) => i !== index));
  };

  const getPopupContainer = (trigger: any) => {
    // Adjust this logic based on your specific requirements
    return trigger.parentElement || document.body;
  };

  //Modal add meet
  const [name, setName] = useState('');
  const [definition, setDefinition] = useState('');
  //const [meet_date_time, setMeetDateTime] = useState(new Date());

  const [metas, setMeetCount] = useState([0]);
  const handleRemoveItem = (item: any) => {
    setMeetCount(metas.filter(item => item !== item));
  };

  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<any>([]);
  
  useEffect(() => {
    (
      async () => {
        if (id > 0 && refreshData) {
          const { data } = await axios.get(`recruitment/meets/${id}`);
          setMeet(data);
          setMeetMeta(data.meta);
          setDataSource(data.meta);
          setRefreshData(false);
        }
      }
    )()
  }, [id,refreshData]);

  //editable
  
  const [count, setCount] = useState(2);
  const handleDelete =  async (id:any) => {
    const newData = dataSource.filter((item:any) => item.id !== id);
    setDataSource(newData);
    await axios.delete(`meet-meta/${id}`);
  };
  const defaultColumns = [
    {
      dataIndex: 'no',
      width:'10%',
      render: (text:any,record:any,index:any) => {
        return (
          <>
            {index + 1}.
          </>
        );
      },
    },
    
    {
      title: 'Tarih / Saat',
      dataIndex: 'meet_date_time',
      editable: true
    },    
    {
      title: 'Başlık',
      dataIndex: 'name',
      editable: true,
    },
    {
      title: 'Açıklama',
      dataIndex: 'definition',
      editable: true,
    },
    {
      dataIndex: 'operation',
      width:'10%',
      render: (_:any, record:any) =>
        dataSource.length >= 1 ? (
          <Popconfirm
  cancelText="İptal"
  okText="Tamam"
  title="Kalıcı olarak silinecektir. Emin misiniz?"
  style={{ zIndex: 9999999999 }}
  getPopupContainer={getPopupContainer}  // Set the getPopupContainer function if needed
  onConfirm={() => handleDelete(record.id)}
>
  <a className="text-danger"><AiOutlineDelete /></a>
</Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = async () => {
    const defaultDateTime = moment(); // Varsayılan değeri burada alın

    const newData = {
      key: dataSource.length + 1,
      no: dataSource.length + 1,
      name: '-',
      meet_date_time: defaultDateTime.format("YYYY-MM-DD HH:mm"),
      meet_date: defaultDateTime.format('YYYY-MM-DD'),
      meet_time: defaultDateTime.format('HH:mm'),
      definition: '-',
      meet_id: id,
    };
    await axios.post(`meet-meta`, newData);
    setCount(count + 1);
    setRefreshData(true);
  };
 
  const handleSave = async (row: any) => {
    try {
      const meetMetaId = row.id;
      const updatedRow = {
        ...row,
        meet_date_time: moment(row.meet_date_time).format("YYYY-MM-DD HH:mm"),
        meet_date: moment(row.meet_date_time).format("YYYY-MM-DD"),
        meet_time: moment(row.meet_date_time).format("HH:mm"),
      };
  
      await axios.put(`meet-meta/${meetMetaId}`, updatedRow);
  
      const newData = dataSource.map((item: any) =>
        item.id === meetMetaId ? updatedRow : item
      );
  
      setDataSource(newData);
      setUpdateMeetMeta(!update_meet_meta);
    } catch (error) {
      console.error('Error updating meet meta:', error);
    }
  };
  
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record:any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
      render: (text: any, record: any) => {
        if (col.dataIndex === 'meet_date_time') {
          return `${record.meet_date_time}`;
        }
        if (col.dataIndex === 'meet_date') {
          return `${record.meet_date}`;
        }
        if (col.dataIndex === 'meet_time') {
          return `${record.meet_time}`;
        }
        return text;
      },
    };
  });

  if (!meet) {
    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-5 mb-5">
          <div className="d-flex justify-content-between bd-highlight mb-3">
            <Spin></Spin>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
    
      <table className="table table-hover table-striped">
        <thead>
          <tr>
            <th>Ad Soyad</th>
            <th>{meet.first_name} {meet.last_name}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Telefon</th>
            <td><Tel phone={meet.phone}>{meet.phone}</Tel></td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td><Mailto email={meet.email} body="Merhaba">{meet.email}</Mailto></td>
          </tr>
          <tr>
            <th scope="row">Adres</th>
            <td>{meet.address}</td>
          </tr>
          <tr>
            <th scope="row">İlan Türü</th>
            <td>{meet.advertisementType?.name}</td>
          </tr>
          <tr>
            <th scope="row">İletişim Kaynağı</th>
            <td><Tag color={meet.communication?.color}>{meet.communication?.name}</Tag></td>
          </tr>
          <tr>
            <th scope="row">Durum</th>
            <td><Tag color={meet.result?.color}>{meet.result?.name}</Tag></td>
          </tr>
          <tr>
            <th scope="row">Not</th>
            <td>{meet.note}</td>
          </tr>
        </tbody>
      </table>
      <Button
        onClick={handleAdd}
        type="primary"
        className="btn btn-soft-primary waves-effect waves-light btn-sm float-end mb-2" 
      >
        <FiPlus /> Görüşme Ekle
      </Button>
      <Table
            title={() => (
              <div className="row">
              <div className="text-center" style={{padding: '0.75rem 0.75rem', background: '#e3e4e8', fontWeight: '500'}}>
                GÖRÜŞMELER
              </div>
              </div>
            )}
      
        style={{ overflowX: 'auto', maxWidth: '100%'}}
        scroll={{y: 1400}}
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={dataSource}
        columns={columns}
      />

{/* <ReminderManager
                                    pageInfo={{
                                        title: 'HATIRLARMALAR'
                                    }}
                                    exportData={(propertyData: any) => {
                                        setCount(propertyData);
                                    }}
                                /> */}
    </>
  );
}

export default MeetDetail;


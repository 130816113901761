import React, { SyntheticEvent, useEffect, useState, createContext, useContext } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import Wrapper from "../components/layout/wrapper";
import { GoogleMap, LoadScript, HeatmapLayer, Marker, OverlayView } from '@react-google-maps/api';
import { DatePicker, Avatar, Popover, Tag, notification, Space, Radio, Carousel, Rate } from 'antd';
import { connect } from "react-redux";

import CountUp from 'react-countup';

import {
    Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    BarElement,
    Title,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import axios from "axios";
import { Transaction } from "../models/accounting/transaction/transaction";
import { Property } from "../models/general/property/property";
import { User } from "../models/user";
import { useUserContext } from "../providers/user/userProvider";
import { Attribute } from "../models/attribute/attribute";
import { useAttribute } from "../contexts/attribute/attributeContext";
import { useGiroTypeContext } from "../providers/attribute/giroType/giroTypeProvider";
import { useIdTypeContext } from "../providers/attribute/idType/idTypeProvider";
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { AiFillCaretDown, AiFillShop } from "react-icons/ai";
import { BsFillHouseDoorFill, BsHouse, BsTextarea } from "react-icons/bs";
import { RiLayoutMasonryLine } from "react-icons/ri";
import ProfilePhoto from "../components/user/profilePhoto";
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import TransactionDetail from "../components/transaction/detail";
import { NumericFormat } from "react-number-format";
import CustomInput from "../components/form/customInput";
import AgentGiroList from "../components/dashboard/agent/list";
import GiroList from "../components/dashboard/giro/list";
import { Customer } from "../models/accounting/customer/customer";
import SatisfactionSurveyDetail from "../components/customer/satisfaction-survey/detail";
import { WorkSchedules } from "../models/employee/workSchedule/workSchedule";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import WatchScheduleList from "../components/dashboard/watchSchedule/list";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

dayjs.locale("tr");
const { RangePicker } = DatePicker;
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}

// const meetsChartData = {
//     labels: [''],
//     datasets: [
//         {
//             label: '# of Votes',
//             data: [0],
//             backgroundColor: [
//                 'rgb(105, 81, 206)',
//                 'rgb(240, 77, 128)',
//                 'rgba(255, 204, 90, 0.85)',
//                 'rgb(22, 218, 241)'
//             ],
//             borderColor: [
//                 'rgb(105, 81, 206)',
//                 'rgb(240, 77, 128)',
//                 'rgba(255, 204, 90, 0.85)',
//                 'rgb(22, 218, 241)'
//             ],
//             borderWidth: 1,
//         },
//     ],
// };
const chartMaxRedirectData = {
    labels: [
        'Satıcı',
        'Alıcı',
        'Kiralayan',
        'Kiracı'
    ],
    datasets: [
        {
            label: '# of Votes',
            data: [38, 84, 13, 72],
            backgroundColor: [
                'rgb(105, 81, 206)',
                'rgb(240, 77, 128)',
                'rgba(255, 204, 90, 0.85)',
                'rgb(22, 218, 241)'
            ],
            borderColor: [
                'rgb(105, 81, 206)',
                'rgb(240, 77, 128)',
                'rgba(255, 204, 90, 0.85)',
                'rgb(22, 218, 241)'
            ],
            borderWidth: 1,
        },
    ],
};

const chartSales = {
    labels: [''],
    datasets: [
        {
            label: '# of Votes',
            data: [0],
            backgroundColor: [
                'rgb(105, 81, 206)',
                'rgb(240, 77, 128)',
                'rgba(255, 204, 90, 0.85)',
                'rgb(22, 218, 241)'
            ],
            borderColor: [
                'rgb(105, 81, 206)',
                'rgb(240, 77, 128)',
                'rgba(255, 204, 90, 0.85)',
                'rgb(22, 218, 241)'
            ],
            borderWidth: 1,
        },
    ],
};

const chartRent = {
    labels: [
        'Konut',
        'İşyeri'
    ],
    datasets: [
        {
            label: '# of Votes',
            data: [42, 23],
            backgroundColor: [
                'rgb(105, 81, 206)',
                'rgb(240, 77, 128)',
                'rgba(255, 204, 90, 0.85)',
                'rgb(22, 218, 241)'
            ],
            borderColor: [
                'rgb(105, 81, 206)',
                'rgb(240, 77, 128)',
                'rgba(255, 204, 90, 0.85)',
                'rgb(22, 218, 241)'
            ],
            borderWidth: 1,
        },
    ],
};


interface Veri {
    name: string;
    visible: boolean;
}

const Dashboard = (props: { user: User }) => {
    const [users, setUsers] = useUserContext();
    const [giro_types, setGiroTypes] = useGiroTypeContext();
    const [id_types, setIdTypes] = useIdTypeContext();

    const [verData, setVerData] = useState({ name: 'Ver', visible: true });
    const [attributes, setAttributes] = useState([]);

    const [meet_report, setMeetReport] = useState<any | null>([]);
    const [meet_results, setMeetResults] = useState([]);

    const [transactions_report, setTransactionsReport] = useState<any | null>([]);
    const [transactions_sum_count_report, setTransactionsSumCountReport] = useState<any | null>([]);

    const [selectedDateName, setSelectedDateName] = useState<string>('Bu Ay');
    const [seeMonth, setSeeMonths] = useState<number>(24);
    const [seeTransactionType, setSeeTransactionType] = useState<number>(1);


    const [send_filter, setSendFilter] = useState(true);
    const [send_transaction_filter, setSendTransactionFilter] = useState(true);


    const [meetsChartData, setMeetsChartData] = useState({
        labels: [''],
        datasets: [
            {
                label: '# of Votes',
                data: [0],
                backgroundColor: [
                    'rgb(105, 81, 206)',
                    'rgb(240, 77, 128)',
                    'rgba(255, 204, 90, 0.85)',
                    'rgb(22, 218, 241)'
                ],
                borderColor: [
                    'rgb(105, 81, 206)',
                    'rgb(240, 77, 128)',
                    'rgba(255, 204, 90, 0.85)',
                    'rgb(22, 218, 241)'
                ],
                borderWidth: 1,
            },
        ],
    });

    const [chartsData, setChartsData] = useState({
        chartSales: {
            labels: [''],
            datasets: [
                {
                    label: '# of Votes',
                    data: [0],
                    backgroundColor: [
                        'rgb(105, 81, 206)',
                        'rgb(240, 77, 128)',
                        'rgba(255, 204, 90, 0.85)',
                        'rgb(22, 218, 241)'
                    ],
                    borderColor: [
                        'rgb(105, 81, 206)',
                        'rgb(240, 77, 128)',
                        'rgba(255, 204, 90, 0.85)',
                        'rgb(22, 218, 241)'
                    ],
                    borderWidth: 1,
                },
            ],
        },
        chartRent: {
            labels: [
                'Konut',
                'İşyeri'
            ],
            datasets: [
                {
                    label: '# of Votes',
                    data: [42, 23],
                    backgroundColor: [
                        'rgb(105, 81, 206)',
                        'rgb(240, 77, 128)',
                        'rgba(255, 204, 90, 0.85)',
                        'rgb(22, 218, 241)'
                    ],
                    borderColor: [
                        'rgb(105, 81, 206)',
                        'rgb(240, 77, 128)',
                        'rgba(255, 204, 90, 0.85)',
                        'rgb(22, 218, 241)'
                    ],
                    borderWidth: 1,
                },
            ],
        },
    });


    useEffect(() => {
        const meetReportKeys = Object.keys(meet_report);
        const wantedProperties = meetReportKeys.filter(property => property.startsWith('total_result_'));
        const meetReportValues = wantedProperties.map(property => meet_report[property]);
        setMeetsChartData(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: meetReportValues
            }]
        }));
    }, [meet_report]);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/52');

                setMeetResults(data.data);
            }
        )()
    }, []);
    meetsChartData.labels = meet_results.map((result: any) => result.name.toString());




    const [transactionsChartData, setTransactionsChartData] = useState<{ labels: string[], datasets: any[] }>({ labels: [], datasets: [] });
    useEffect(() => {
        if (transactions_report?.giro_type?.total_transaction?.Satış || transactions_report?.giro_type?.total_giro?.Satış) {
            var salesData: any;
            var rentData: any;
            if (seeTransactionType == 1) {
                salesData = transactions_report.giro_type.total_transaction.Satış;
                rentData = transactions_report.giro_type.total_transaction.Kiralama;
            } else if (seeTransactionType == 2) {
                salesData = transactions_report.giro_type.total_giro.Satış;
                rentData = transactions_report.giro_type.total_giro.Kiralama;
            }
            const salesLabels = Object.keys(salesData);
            const salesDatasets = salesLabels.map(month => salesData[month]);

            const rentLabels = Object.keys(rentData);
            const rentDatasets = rentLabels.map(month => rentData[month]);
            setTransactionsChartData({
                labels: salesLabels,
                datasets: [{
                    label: 'Satış',
                    data: salesDatasets,
                    backgroundColor: Array(data.length).fill('rgb(240, 77, 128)'),
                    borderColor: Array(data.length).fill('rgb(240, 77, 128)'),
                    borderWidth: 1
                }, {
                    label: 'Kiralama',
                    data: rentDatasets,
                    backgroundColor: Array(data.length).fill('rgb(105, 81, 206)'),
                    borderColor: Array(data.length).fill('rgb(105, 81, 206)'),
                    borderWidth: 1
                }]
            });
        }
    }, [transactions_report]);


    useEffect(() => {
        (
            async () => {
                const generateChartData = (propertyGenus: any) => {
                    const labels: any = [];
                    const data: any = [];

                    Object.values(propertyGenus).forEach((item: any) => {
                        labels.push(item.name);
                        data.push(item.value);
                    });

                    return {
                        labels: labels,
                        datasets: [
                            {
                                label: '# of Votes',
                                data: data,
                                backgroundColor: [
                                    'rgb(105, 81, 206)',
                                    'rgb(240, 77, 128)',
                                    'rgba(255, 204, 90, 0.85)',
                                    'rgb(22, 218, 241)'
                                ],
                                borderColor: [
                                    'rgb(105, 81, 206)',
                                    'rgb(240, 77, 128)',
                                    'rgba(255, 204, 90, 0.85)',
                                    'rgb(22, 218, 241)'
                                ],
                                borderWidth: 1,
                            }
                        ],
                    };
                };

                if (send_filter) {
                    axios.get(`dashboard?filter_between[]=${start_date}&filter_between[]=${end_date}&filter_see_month=${seeMonth}`)
                        .then(({ data }) => {
                            setMeetReport(data.meet.report);
                            setTransactionsSumCountReport(data.transactions.report);

                            // Döngüyle chartSales ve chartRent verilerini oluştur
                            const chartSalesData = generateChartData(data.transactions.report.giro_type.total['116'].property_genus);
                            const chartRentData = generateChartData(data.transactions.report.giro_type.total['117'].property_genus);

                            setChartsData({
                                chartSales: chartSalesData,
                                chartRent: chartRentData
                            });
                        });
                    setSendFilter(false);
                }
            }
        )()
    }, [send_filter]);

    useEffect(() => {
        (
            async () => {
                if (send_transaction_filter) {
                    axios.get(`dashboard/transaction-report?filter_see_month=${seeMonth}&filter_see_transaction_type=${seeTransactionType}`)
                        .then(({ data }) => {
                            setTransactionsReport(data.transactions.report);

                        });
                    setSendTransactionFilter(false);
                }
            }
        )()
    }, [send_transaction_filter]);




    useEffect(() => {
        localStorage.removeItem('data6');
        localStorage.removeItem('lastUpdate6');
        localStorage.removeItem('data7');
        localStorage.removeItem('lastUpdate7');

        const storedData = localStorage.getItem('data8');

        if (!storedData) {
            localStorage.setItem('data8', JSON.stringify(verData));
        } else {
            setVerData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        const lastUpdate = localStorage.getItem('lastUpdate8');

        if (!lastUpdate || new Date().getTime() - new Date(lastUpdate).getTime() > 1000 * 60 * 60 * 24 * 7) {
            const newData = { name: 'İşlem güncelleme alanında sözleşme tarihi değiştirme sorunu çözüldü', visible: true };

            localStorage.removeItem('data8');
            localStorage.setItem('data8', JSON.stringify(newData));
            localStorage.setItem('lastUpdate8', new Date().toString());

            showNotification(newData.name);

            setVerData({ ...verData, visible: false });
        }
    }, [verData]);

    useEffect(() => {
        localStorage.setItem('data8', JSON.stringify(verData));
    }, [verData]);

    const showNotification = (name: string) => {
        const verInfo = localStorage.getItem('ver');
        notification.open({
            type: 'info',
            duration: 0,
            message: `Versiyon ${verInfo} Güncellemesi`,
            description: (
                <div>
                    <strong>Güncelleme kapsımında yapılan yenilikler</strong>
                    <ul className="list-unstyled">
                        <li>✔ Gösterge panelinde yeni özellikler getirildi.</li>
                        <li>✔ Ön kapora sözleşmesi sayfası eklendi.</li>
                    </ul>
                </div>
            ),
        });
    };

    const [properties, setProperties] = useState([]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`properties?page=1`);

                setProperties(data);
            }
        )()
    }, []);

    const [transactions, setTransactions] = useState([]);
    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`giros?limit=10000&page=1&filter_between[]=${start_date}&filter_between[]=${end_date}`);

                setTransactions(data.transactions.data);
            }
        )()
    }, [send_filter]);

    const [coordinates, setCoordinates] = useState({ lat: 38.885464, lng: 40.496625, weight: Math.floor(Math.random() * Math.floor(5)) });
    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const data = [
        { lat: 37.782551, lng: -122.445368 },
        { lat: 37.782745, lng: -122.444586 },
        // ...
    ];

    const onLoad = (heatmapLayer: any) => {
        console.log('HeatmapLayer onLoad heatmapLayer: ', heatmapLayer)
    }

    const onUnmount = (heatmapLayer: any) => {
        console.log('HeatmapLayer onUnmount heatmapLayer: ', heatmapLayer)
    }

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 16
    };

    const mapData = transactions.filter((transaction: Transaction) => transaction.property?.coordinate).map((transaction: Transaction) => {
        const splitCoord = transaction.property?.coordinate.split(",");
        let markerType = 'blue';
        let color = 'geekblue';
        if (transaction.giro_type.id === 116) {
            markerType = 'red';
            color = 'red';
        }
        let markerIcon = 'house';

        switch (transaction.property.propertyGenus.id) {
            case 8:
                markerIcon = 'plot';
                break;
            case 9:
                markerIcon = 'commercial';
                break;
            case 10:
                markerIcon = 'field';
                break;
        }
        if (splitCoord && splitCoord.length > 1) {
            return {
                lat: parseFloat(splitCoord[0]),
                lng: parseFloat(splitCoord[1]),
                marker: markerType + "-" + markerIcon,
                id: transaction.id,
                markerIcon: markerIcon,
                color: color,
                fullName: transaction.agent.first_name + ' ' + transaction.agent.last_name,
                content: transaction.agent.first_name.substring(0, 1) + transaction.agent.last_name.substring(0, 1),
                giro_type_id: transaction.giro_type.id,
                seller_service_fee: transaction.seller_service_fee,
                buyer_service_fee: transaction.buyer_service_fee,
                office_referral_fee: transaction.office_referral_fee,
                other_realtor_id: transaction.realtor?.id,
                realtor_full_name: transaction.realtor?.first_name + ' ' + transaction.realtor?.last_name,
                out_office_referral_fee: transaction.out_office_referral_fee,
                total_service_fee: transaction.total_service_fee,
                agent_percent: transaction.agent_percent,
                agent_service_fee: transaction.agent_service_fee,
                agent_team_leader_id: transaction.agent.team_leader_id,
                agent_team_leader_percent: transaction.agent.team_leader_percent,
                team_leader_service_fee: transaction.agent.team_leader_price,
                office_service_fee: transaction.office_service_fee,

            };
        }
    });

    //     const heatmapData = mapData.map((d) => {
    //     return {
    //     lat: d.lat,
    //     lng: d.lng,
    //     weight: Math.floor(Math.random() * Math.floor(5)),
    //     }
    //     });


    // const mapDAta=
    // {
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 36.87524849186124, lng: 30.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) },
    // { lat: 37.87524849186124, lng: 31.64364152042535,weight: Math.floor(Math.random() * Math.floor(5)) }}; 
    //   const [coordinates2, setCoordinates2] = useState([
    //     positions:

    //   ]);

    const [temperatures, setTemperatures] = useState([20, 25, 30, 35, 40, 45, 50]);


    //Transciton Modal 

    const [show_id, setShowID] = useState<number>(0);
    const [show_full_name, setShowFullName] = useState<any | null>('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
    const endOfWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');

    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    const startOfYear = moment().startOf('year').format('YYYY-MM-DD');
    const endOfYear = moment().endOf('year').format('YYYY-MM-DD');

    const modalLoaded = () => {
    };

    const contentStyle: React.CSSProperties = {
        minHeight: '180px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };
    const [score_names, setScoreNames] = useState<any>();
    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`attributes/46`);
                const updatedDesc = data?.data?.map((item: Attribute) => item.name);
                setScoreNames(updatedDesc);
            }
        )()
    }, []);

    //Table
    interface DataType {
        last_name: any;
        first_name: any;
        key: React.Key;
        id(id: any): unknown;
        month: any;
        created_at: string;
        customer: Customer;
        category: string;
        avg_rate: any;
        agent: User;
        comment: any;
    }
    const [satisfaction_survey, setSatisfactionSurvey] = useState<DataType[]>([]);
    const [satisfaction_survey_reports, setSatisfactionSurveyReports] = useState<any | null>(null);
    useEffect(() => {
        (
            async () => {

                axios.get(`customer/satisfaction-survey?limit=10&page=1&filter_between[]=${startOfMonth}&filter_between[]=${endOfMonth}&filter_type=149&sort[by]=created_at&sort[order]=desc`)
                    .then(({ data }) => {
                        setSatisfactionSurvey(data?.satisfaction_survey?.data);
                        setSatisfactionSurveyReports(data.satisfaction_survey.report);
                    });
            }
        )()
    }, []);

    const [showFullComment, setShowFullComment] = useState(false);
    const [showFullCommentId, setShowFullCommentId] = useState(null);

    const toggleComment = (id) => {
        setShowFullCommentId(id);
        setShowFullComment(!showFullComment);
    };

    const [satisfaction_survey_show_id, setSatisfactionSurveyShowID] = useState<any | null>(0);
    const [show_satisfaction_survey_full_name, setShowSatisfactionSurveyFullName] = useState<any | null>('');
    const [satisfaction_survey_show, setSatisfactionSurveyShow] = useState(false);

    const handleSatisfactionSurveyClose = () => setSatisfactionSurveyShow(false);

    const shortenText = (text: any, maxLength: number,) => {
        if (!showFullComment || showFullCommentId !== text.id) {
            if (text.length <= maxLength) {
                return text;
            }
            return `${text.substring(0, maxLength)}...`;

        }
        return text;
    };

    const [workSchedules, setWorkSchedules] = useState([]);

    useEffect(() => {
        (
            async () => {
                axios.get(`employee/work-schedules?limit=500&sort[by]=created_at&sort[order]=desc&page=1&filter_between[]=${startOfMonth}&filter_between[]=${endOfMonth}`)
                    .then(({ data }) => {
                        setWorkSchedules(data.work_schedule.data.data);
                    });
            }
        )()
    }, []);

    const [watchSchedules, setWatchSchedules] = useState([]);

    useEffect(() => {
        (
            async () => {
                const todayDate = moment().format('YYYY-MM-DD');
                const { data } = await axios.get(`asistant/watch-schedules?limit=50&page=1&filter_between[]=${startOfWeek}&filter_between[]=${todayDate}&&sort[by]=schedule_date&sort[order]=desc`);
                setWatchSchedules(data.watch_schedule.data.data);
            }
        )()
    }, []);


    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Remax ELITE</h4>
                        {props.user.role_id === 1 || props.user.id === 1 ?
                            <Space>
                                <label className="pt-2">Rapor Tarih Aralığı</label>
                                <div className="form-group select-style">
                                    <RangePicker
                                        format={'DD/MM/YYYY'}
                                        locale={locale}
                                        ranges={rangePresets}
                                        value={date_range}
                                        style={{ width: '100%' }}
                                        onChange={(value: any, dateString) => {
                                            setDateRange(value);
                                            setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                            setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                            setSendFilter(true);

                                            const selectedRangeKey = Object.keys(rangePresets).find(key => {
                                                const range = rangePresets[key];
                                                return dayjs(value[0]).isSame(range[0]) && dayjs(value[1]).isSame(range[1]);
                                            });

                                            if (selectedRangeKey) {
                                                setSelectedDateName(selectedRangeKey);
                                            } else {
                                                setSelectedDateName(dateString.toString().split(',')[0] + '-' + dateString.toString().split(',')[1]);
                                            }

                                        }
                                        }
                                    />
                                </div>

                            </Space> : null}
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="javascript: void(0);">Gösterge Paneli</a></li>
                                <li className="breadcrumb-item active">Remax ELITE</li>

                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {props.user.role_id === 1 || props.user.id === 1 ?
                <>
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="card card-h-100">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Görüşmeler</span>
                                            <h4 className="mb-3">
                                                <CountUp end={meet_report?.total} />
                                            </h4>
                                            <div className="text-nowrap">
                                                <span className="ms-1 text-muted font-size-13">{selectedDateName}</span>
                                            </div>
                                        </div>

                                        <div className="flex-shrink-0 text-end dash-widget">
                                            <Doughnut data={meetsChartData}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: true,
                                                    cutout: 25,
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6">
                            <div className="card card-h-100">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Nöbet Müşterileri</span>
                                            <h4 className="mb-3">
                                                <CountUp end={387} />
                                            </h4>
                                            <div className="text-nowrap">
                                                <span className="ms-1 text-muted font-size-13">{selectedDateName}</span>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 text-end dash-widget">
                                            <Doughnut data={chartMaxRedirectData}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: true,
                                                    cutout: 25,
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6">
                            <div className="card card-h-100">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Toplam Satış</span>
                                            <h4 className="mb-3">
                                                <CountUp end={transactions_sum_count_report?.total_giro_type_116} />
                                            </h4>
                                            <div className="text-nowrap">
                                                <span className="badge bg-soft-success text-success">₺{transactions_sum_count_report?.giro_type?.sum?.total_service_fee?.["116"]}</span>
                                                <span className="ms-1 text-muted font-size-13">{selectedDateName}</span>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 text-end dash-widget">
                                            <Doughnut data={chartsData.chartSales}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: true,
                                                    cutout: 25,
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6">
                            <div className="card card-h-100">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Toplam Kiralama</span>
                                            <h4 className="mb-3">
                                                <CountUp end={transactions_sum_count_report?.total_giro_type_117} />
                                            </h4>
                                            <div className="text-nowrap">
                                                <span className="badge bg-soft-success text-success">₺{transactions_sum_count_report?.giro_type?.sum?.total_service_fee?.["117"]}</span>
                                                <span className="ms-1 text-muted font-size-13">{selectedDateName}</span>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 text-end dash-widget">
                                            <Doughnut data={chartsData.chartRent}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: true,
                                                    cutout: 25,
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <Carousel autoplay autoplaySpeed={15000} dots={false}>
                                {watchSchedules.map((watchSchedules: any) => {
                                    return (
                                        <>
                                            <div className="card-header align-items-center d-flex">
                                                <h4 className="card-title mb-0 flex-grow-1">Nöbetçi Danışman {moment(watchSchedules.schedule_date).format('DD/MM/YYYY')}</h4>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <ProfilePhoto id={watchSchedules.user?.id} imgClass="avatar-lg rounded-circle img-thumbnail" />
                                                    </div>
                                                    <div className="flex-1 ms-3">
                                                        <h5 className="font-size-15 mb-1"><a href="#" className="text-dark">{watchSchedules.user?.first_name} {watchSchedules.user?.last_name}</a></h5>
                                                        <p className="text-muted mb-0">{watchSchedules.user?.category?.name}</p>
                                                    </div>
                                                </div>
                                                <div className="mt-3 pt-1">
                                                    <WatchScheduleList user_id={watchSchedules.user?.id} meet_date={watchSchedules.schedule_date} />
                                                </div>
                                            </div>
                                        </>
                                       )
                                    })}
                                </Carousel>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Müşteri Yorumları</h4>
                                </div>
                                <div className="card-body">
                                    <Carousel autoplay autoplaySpeed={8000} dots={false}>
                                        {score_names && satisfaction_survey.map((satisfaction_survey: DataType) => {
                                            return (
                                                <div key={satisfaction_survey.id} style={contentStyle}>
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <ProfilePhoto id={satisfaction_survey.agent?.id} imgClass="avatar-lg rounded-circle img-thumbnail" />
                                                        </div>
                                                        <div className="flex-1 ms-3">
                                                            <h5 className="font-size-15 mb-1">
                                                            {satisfaction_survey.agent && (
                    <a onClick={() => { 
                        setSatisfactionSurveyShow(true); 
                        setSatisfactionSurveyShowID(satisfaction_survey?.id); 
                        setShowSatisfactionSurveyFullName(`${satisfaction_survey.first_name} ${satisfaction_survey.last_name}  -  ${satisfaction_survey.customer?.first_name} ${satisfaction_survey.customer?.last_name.toLocaleUpperCase('tr-TR')}`); 
                    }} className="text-dark">{satisfaction_survey.agent.first_name} {satisfaction_survey.agent.last_name}</a>
                )}
                </h5>
                                                            <div className="text-muted mb-0">
                                                                <Rate allowHalf disabled tooltips={score_names} value={Math.round(satisfaction_survey.avg_rate)} />
                                                                <strong className="ms-2">
                                                                    <NumericFormat
                                                                        value={satisfaction_survey.avg_rate?.toString().replace('.', ',')}
                                                                        thousandSeparator="."
                                                                        decimalSeparator=","
                                                                        displayType="text"
                                                                    />
                                                                </strong>
                                                                <span className="ms-2">
                                                                    {score_names[Math.round(satisfaction_survey.avg_rate) - 1]}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bg-light mt-4">
                                                        <div className="card-body pb-0 align-item-center">
                                                            <div style={{ height: showFullComment ? 'auto' : '180px', minHeight: '180px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' }}>
                                                                <p className="text-muted mt-2">
                                                                    {showFullComment ? satisfaction_survey.comment : shortenText(satisfaction_survey.comment, 180)}

                                                                </p>
                                                                {satisfaction_survey.comment.length > 180 && (
                                                                    <a className="btn btn-link p-0" onClick={() => toggleComment(satisfaction_survey.id)}>
                                                                        {showFullComment ? <>Daha az  <FiChevronUp /></> : <>Daha fazla <FiChevronDown /></>}
                                                                    </a>
                                                                )}

                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <h5 className="font-size-15 mb-1">{satisfaction_survey.customer.first_name} {satisfaction_survey.customer.last_name.toLocaleUpperCase('tr-TR')}</h5>
                                                                <p className="text-muted">{satisfaction_survey.created_at}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Personel Aktiviteleri</h4>
                                </div>
                                <div className="card-body">
                                    <Carousel autoplay autoplaySpeed={8000} dots={false}>
                                        {workSchedules.map((workSchedules: WorkSchedules) => {
                                            return (
                                                <div style={contentStyle}>
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <ProfilePhoto id={workSchedules.user?.id} imgClass="avatar-lg rounded-circle img-thumbnail" />
                                                        </div>
                                                        <div className="flex-1 ms-3">
                                                            <h5 className="font-size-15 mb-1">
                                                                {workSchedules.user.first_name} {workSchedules.user.last_name}
                                                            </h5>
                                                            <div className="text-muted mb-0">
                                                                {moment(workSchedules.working_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bg-light mt-4">
                                                        <div className="card-body pb-0">
                                                            <div style={{ height: showFullComment ? 'auto' : '180px', minHeight: '180px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' }}>
                                                                <p className="text-muted mt-2" >
                                                                    {showFullComment ? <div dangerouslySetInnerHTML={{ __html: workSchedules.definition }} /> : <div dangerouslySetInnerHTML={{ __html: shortenText(workSchedules.definition, 180) }} />}

                                                                </p>
                                                                {workSchedules.definition.length > 180 && (
                                                                    <a className="btn btn-link p-0" onClick={() => toggleComment(satisfaction_survey.id)}>
                                                                        {showFullComment ? <>Daha az  <FiChevronUp /></> : <>Daha fazla <FiChevronDown /></>}
                                                                    </a>
                                                                )}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-4">
                            <div className="card  pb-0">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Son İşlemler</h4>
                                </div>

                                <div className="card-body px-3">
                                    <GiroList start_date={startOfMonth} end_date={endOfMonth} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Haftanın Danışmanları</h4>
                                </div>

                                <div className="card-body px-3 pb-0">
                                    <AgentGiroList start_date={startOfWeek} end_date={endOfWeek} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card  pb-0">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Ayın Danışmanları</h4>
                                </div>

                                <div className="card-body px-3 pb-0">
                                    <AgentGiroList start_date={startOfMonth} end_date={endOfMonth} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card  pb-0">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Yılın Danışmanları</h4>
                                </div>

                                <div className="card-body px-3 pb-0">
                                    <AgentGiroList start_date={startOfYear} end_date={endOfYear} />
                                </div>
                            </div>
                        </div>
                     

                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap align-items-center mb-2">
                                        <h5 className="card-title me-2">Anlık İşlem Haritası</h5>
                                        <div className="ms-auto">
                                            <div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-xl-12">
                                            {/* <GoogleMap
                                            mapContainerStyle={{ height: '300px', width: '100%' }}
                                            zoom={13}
                                            center={{ lat: 37.782551, lng: -122.445368 }}
                                            >
                                            <HeatmapLayer data={mapData} />
                                            </GoogleMap> */}
                                            <LoadScript
                                                googleMapsApiKey="AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo">
                                                <GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    center={coordinates}
                                                    zoom={16}
                                                >
                                                    {mapData.map(dt => {
                                                        if (dt) {
                                                            return (
                                                                <OverlayView
                                                                    position={{ lat: dt.lat, lng: dt.lng }}
                                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                                >
                                                                    <div title={dt?.fullName} onClick={e => { setShow(true); setShowID(dt?.id); setShowFullName(`${dt?.fullName}`); }}>
                                                                        <Popover content={
                                                                            (
                                                                                <div>
                                                                                    <table className="table table-hover table-striped">
                                                                                        <thead>
                                                                                            <tr className="table-secondary">
                                                                                                <th colSpan={2}>İŞLEM BİLGİLERİ</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <th>{dt?.giro_type_id === 116 ? 'SATICI HİZMET BEDELİ' : 'KİRALAMA HİZMET BEDELİ'}</th>
                                                                                                <td>
                                                                                                    <strong>
                                                                                                        <NumericFormat
                                                                                                            value={dt?.seller_service_fee.toString().replace('.', ',')}
                                                                                                            thousandSeparator="."
                                                                                                            decimalSeparator=","
                                                                                                            displayType="text"
                                                                                                        /> TL
                                                                                                    </strong>
                                                                                                </td>
                                                                                            </tr>
                                                                                            {dt?.giro_type_id === 116 ?
                                                                                                <tr>
                                                                                                    <th>ALICI HİZMET BEDELİ</th>
                                                                                                    <td>
                                                                                                        <strong>
                                                                                                            <NumericFormat
                                                                                                                value={dt?.buyer_service_fee.toString().replace('.', ',')}
                                                                                                                thousandSeparator="."
                                                                                                                decimalSeparator=","
                                                                                                                displayType="text"
                                                                                                            /> TL
                                                                                                        </strong>
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                            {dt?.office_referral_fee > 0 ?
                                                                                                <tr>
                                                                                                    <th>OFİS İLE PAYLAŞIM BEDELİ</th>
                                                                                                    <td>
                                                                                                        <strong>
                                                                                                            <NumericFormat
                                                                                                                value={dt?.office_referral_fee.toString().replace('.', ',')}
                                                                                                                thousandSeparator="."
                                                                                                                decimalSeparator=","
                                                                                                                displayType="text"
                                                                                                            /> TL
                                                                                                        </strong>
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                            {dt?.other_realtor_id > 0 ?
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <th>DİĞER EMLAKÇI</th>
                                                                                                        <td>
                                                                                                            {dt?.realtor_full_name}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th>OFİS DIŞI PAYLAŞIM BEDELİ</th>
                                                                                                        <td>
                                                                                                            <strong>
                                                                                                                <NumericFormat
                                                                                                                    value={dt?.out_office_referral_fee.toString().replace('.', ',')}
                                                                                                                    thousandSeparator="."
                                                                                                                    decimalSeparator=","
                                                                                                                    displayType="text"
                                                                                                                /> TL
                                                                                                            </strong>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>
                                                                                                : null}
                                                                                            <tr>
                                                                                                <th>TOPLAM HİZMET BEDELİ</th>
                                                                                                <td>
                                                                                                    <strong>
                                                                                                        <NumericFormat
                                                                                                            value={dt?.total_service_fee.toString().replace('.', ',')}
                                                                                                            thousandSeparator="."
                                                                                                            decimalSeparator=","
                                                                                                            displayType="text"
                                                                                                        /> TL
                                                                                                    </strong>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>G.D. HİZMET BEDELİ (%{dt?.agent_percent})</th>
                                                                                                <td>
                                                                                                    <strong>
                                                                                                        <NumericFormat
                                                                                                            value={dt?.agent_service_fee.toString().replace('.', ',')}
                                                                                                            thousandSeparator="."
                                                                                                            decimalSeparator=","
                                                                                                            displayType="text"
                                                                                                        /> TL
                                                                                                    </strong>
                                                                                                </td>
                                                                                            </tr>
                                                                                            {dt?.agent_team_leader_id > 0 ?
                                                                                                <tr>
                                                                                                    <th>T. LİDERİ HİZMET BEDELİ (%{dt?.agent_team_leader_percent})</th>
                                                                                                    <td>
                                                                                                        <strong>
                                                                                                            <NumericFormat
                                                                                                                value={dt?.team_leader_service_fee?.toString().replace('.', ',')}
                                                                                                                thousandSeparator="."
                                                                                                                decimalSeparator=","
                                                                                                                displayType="text"
                                                                                                            /> TL
                                                                                                        </strong>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                : null}
                                                                                            <tr>
                                                                                                <th>OFİS HİZMET BEDELİ</th>
                                                                                                <td>
                                                                                                    <strong>
                                                                                                        <NumericFormat
                                                                                                            value={dt?.office_service_fee.toString().replace('.', ',')}
                                                                                                            thousandSeparator="."
                                                                                                            decimalSeparator=","
                                                                                                            displayType="text"
                                                                                                        /> TL
                                                                                                    </strong>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            )
                                                                        } title={dt?.fullName} >
                                                                            <div style={{ position: 'absolute', marginLeft: "10px", marginTop: "-30px" }}>
                                                                                <ProfilePhoto id={dt?.id} imgHeight={30} />
                                                                            </div>
                                                                            <Tag title={dt?.fullName} color={dt?.color}> {dt?.markerIcon == 'house' ? <BsHouse /> : null}{dt?.markerIcon == 'commercial' ? <AiFillShop /> : null} {dt?.markerIcon == 'field' ? <BsTextarea /> : null} {dt?.markerIcon == 'plot' ? <RiLayoutMasonryLine /> : null}   {dt?.content}</Tag>
                                                                            <AiFillCaretDown style={{ position: 'absolute', marginLeft: "-35px", marginTop: "18px" }} />
                                                                        </Popover>
                                                                    </div>
                                                                </OverlayView>

                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </GoogleMap>
                                            </LoadScript>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-lg-flex d-block justify-content-between mb-4">
                                        <Radio.Group
                                            className="d-flex justify-content-center justify-lg-content-end d-sm-block"
                                            value={seeTransactionType}
                                            onChange={(e) => { setSendTransactionFilter(true); setSeeTransactionType(e.target.value); }}
                                            buttonStyle="solid"
                                        >
                                            <Radio.Button value={1}>İşlem Bazlı Rapor</Radio.Button>
                                            <Radio.Button value={2}>Ciro Bazlı Rapor</Radio.Button>
                                        </Radio.Group>
                                        <div className="d-flex justify-content-center justify-lg-content-end">
                                            <div className="align-self-center">
                                                Gösterilen Ay Sayısı
                                            </div>
                                            <div className="ms-2 col-3">
                                                <CustomInput
                                                    type={'number'}
                                                    required={true}
                                                    defaultValue={seeMonth}
                                                    onBlur={(e: any) => { setSeeMonths(e.target.value); setSendTransactionFilter(true); }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-xl-12">
                                            <Bar options={{
                                                responsive: true,
                                            }} data={transactionsChartData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} onEntered={modalLoaded} size="xl" animation={false}>
                        {show_id && show_id !== null && show_id !== undefined &&
                            <>
                                <Modal.Header closeButton>
                                    <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <TransactionDetail id={show_id} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Kapat
                                    </Button>
                                    <Link className="btn btn-primary" to={`/accounting/transactions/${show_id}/edit`}>
                                        Güncelle
                                    </Link>
                                </Modal.Footer>
                            </>
                        }
                    </Modal>
                </>
                :
                <>
                    <div className="row">
                        <div className="profile-content">
                            <div className="row align-items-end">
                                <div className="col-sm">
                                    <div className="d-flex align-items-end mt-5 mt-sm-5 pt-3">
                                        <div className="flex-shrink-0">
                                            <div className="avatar-xxl me-3">
                                                <img src="/assets/images/users/avatar-1.jpg" alt="" className="img-fluid rounded-circle d-block img-thumbnail" />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <div>
                                                <h5 className="font-size-16 mb-1">{props.user.name}</h5>
                                                <p className="text-muted font-size-13 mb-2 pb-2">{props.user?.role?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-xl-8 col-lg-8">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Görev Tanımı</h5>
                                </div>

                                <div className="card-body">
                                    <div>
                                        <div className="pb-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Departman</h5>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex flex-wrap gap-2 font-size-16">
                                        <a href="#" className="badge badge-soft-primary">{props.user.role.name}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }


            <Modal show={satisfaction_survey_show} onHide={handleSatisfactionSurveyClose} size="xl" animation={false}>
                {satisfaction_survey_show_id && satisfaction_survey_show_id !== null && satisfaction_survey_show_id !== undefined &&
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title><ProfilePhoto id={satisfaction_survey_show_id} imgHeight={35} /> <span className="ms-2">{show_satisfaction_survey_full_name}</span> - <span className="text-danger">{satisfaction_survey_show_id}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SatisfactionSurveyDetail id={satisfaction_survey_show_id} />
                        </Modal.Body><Modal.Footer>
                            <Button variant="secondary" onClick={handleSatisfactionSurveyClose}>
                                Kapat
                            </Button>
                        </Modal.Footer>
                    </>
                }
            </Modal>

        </Wrapper>

    )
}


export default connect(
    (state: { user: User }) => {
        return {
            user: state.user
        };
    }
)(Dashboard);
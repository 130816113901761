
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ResourceOfSellContext } from '../../../contexts/attribute/sourceOfSell/sourceOfSellContext';
import { Attribute } from '../../../models/attribute/attribute';

export const ResourceOfSellProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [resources_of_sell, setResourcesOfSell] = useState<Attribute[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getResourcesOfSell() {
        try {
          const { data } = await axios.get('attributes/16');
          if (isMounted) {
            setResourcesOfSell(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      getResourcesOfSell();
  
      return () => {
        isMounted = false;
      };
  }, []);


  return (
      <ResourceOfSellContext.Provider value={[resources_of_sell, setResourcesOfSell]}>
          {children}
      </ResourceOfSellContext.Provider>
  );
}

export const useResourceOfSellContext = () => useContext(ResourceOfSellContext);

import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Link, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import { User } from "../../../models/user";
import { WatchSchedules } from "../../../models/asistant/watchSchedule/watchSchedule";
import { WatchScheduleActions } from "../../../models/asistant/watchSchedule/watchScheduleAction";
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import Moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import trLocale from '@fullcalendar/core/locales/tr';

import { Input, Form, DatePicker, Select } from "antd";

import CustomInput from "../../../components/form/customInput";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import { WorkSchedules } from "../../../models/employee/workSchedule/workSchedule";
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { Category } from "../../../models/user/category";
import isMobile from "../../../components/constant/isMobile";

const { Option } = Select;

dayjs.locale("tr");
const { RangePicker } = DatePicker;
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}

interface Item {
    key: string;
    id: number;
    name: string;
    phone: string;
    email: string;
    customer_type: string;
    property_type: string;
    property_genus: string;
    contact_id: string;
    meet_time: string;
}

const WorkSchedule = (props: { user: User }) => {
    

    const [definition, setDefinition] = useState<any>('');
    const [user_full_name, setUserFullName] = useState<any>('');
    const [working_date, setWorkingDate] = useState('');
    const [btn_text, setBtnText] = useState('Kaydet');
    const [get_type, setGetType] = useState(1);
    const [get_by_id, setGetById] = useState(0);
    const [update, setUpdate] = useState(false);
    const [get_data_status, setGetDataStatus] = useState(false);
    const [see_type, setSeeType] = useState(false);
    const [update_id, setUpdateId] = useState(0);
    const [handle_show, setHandleShow] = useState<any | null>(null);
    const [submit, setSubmit] = useState<any | null>(null);

    const [get_data, setGetData] = useState(true);

    const [workSchedules, setWorkSchedules] = useState([]);

    //For Filter
    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [send_filter, setSendFilter] = useState(false);
    const [category_id, setCategoryId] = useState<any | null>(null);
    const [status_id, setStatusId] = useState<any | null>(null);
    const [filter, setFilter] = useState(false);
    const [filter_url, setFilterUrl] = useState<any | null>('');

    const [clear_filter_inputs, setClearFilterInputs] = useState(false);

    const [users, setUsers] = useState([]);
    const [user_categories, setUserCategories] = useState([]);
    const [selected_employees, setSelectedEmployees] = useState([]);

    useEffect(() => {
        (
            async () => {
                if(category_id>0){
                    const { data } = await axios.get(`users/by-category/${category_id}`);
                    setUsers(data);
                }else{
                    const { data } = await axios.get('users');
                    setUsers(data.data);
                }                
            }

        )()
    }, [category_id]);

    
    useEffect(() => {
        let fUrl = '';
        if (parseInt(status_id) > 0) {
            fUrl = `&filter_status_id=${status_id}`;
        }

        if (selected_employees?.length > 0) {
            selected_employees.forEach((user_id: number) => {
                fUrl = `${fUrl}&filter_employee_id[]=${user_id}`;
            });
        }

        if (category_id > 0) {
            fUrl = `${fUrl}&filter_user_category=${category_id}`;
        }

        setFilterUrl(fUrl);
        setFilter(false);
        setSendFilter(true);
    }, [filter]);

    useEffect(() => {
        (
            async () => {
                if (send_filter) {
                    axios.get(`employee/work-schedules?limit=500&sort[by]=created_at&sort[order]=desc&page=1&filter_between[]=${start_date}&filter_between[]=${end_date}${filter_url}`)
                        .then(({ data }) => {
                            setWorkSchedules(data.work_schedule.data.data);
                        });
                    setSendFilter(false);
                }
            }
        )()
    }, [send_filter]);

    useEffect(() => {
        if (clear_filter_inputs) {
            setSelectedEmployees([]);
            setDateRange([]);
            setCategoryId(null);
            setStatusId(null);
            setClearFilterInputs(false);
            setFilter(true);
        }
    }, [clear_filter_inputs]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('user/speacial-user-categories/0');
                setUserCategories(data);
            }
        )()
    }, []);


    var watchData = [] as any;
    var watchArrdata = [] as any;

    {
        workSchedules.map((w: WorkSchedules) => {
            watchData['id'] = w.id;
            watchData['user_id'] = w.user?.id;
            watchData['date'] = w.working_date;

            watchData['title'] = isMobile ? `${w.user?.first_name.charAt(0)}${w.user?.last_name.charAt(0)}` : `${w.user?.first_name} ${w.user?.last_name}`;
            watchArrdata.push({ ...watchData });
        })

    }
    //Modal    
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setUpdate(false); }

    useEffect(() => {
        setUpdate(false);
        if (handle_show) {
            setShow(true);
            setDefinition('');
            setUserFullName('');
            if (handle_show?.event?._def?.publicId > 0) {
                (isTodayOrYesterday(handle_show?.event?._instance?.range?.start)) ? setSeeType(true) : setSeeType(false);
                setGetType(1);
                setGetById(handle_show?.event?._def?.publicId);
                setWorkingDate(Moment(handle_show?.event?._instance?.range?.start).format("YYYY-MM-DD"));
            } else {
                setWorkingDate(Moment(handle_show.dateStr).format("YYYY-MM-DD"));
                setGetType(2);
                setSeeType(true);
            }
            setGetDataStatus(true);
        }
    }, [handle_show]);

    useEffect(() => {
        if (get_data_status) {
            (
                async () => {
                    setUpdate(false);
                    setBtnText('Kaydet');
                    setDefinition('');
                    setUserFullName('');
                    if (get_type == 1) {
                        if (get_by_id > 0) {
                            const { data } = await axios.get(`employee/work-schedules/${get_by_id}`);
                            setDefinition(data.definition);
                            setUserFullName(`${data.user.first_name} ${data.user.last_name}`);
                            setUpdateId(data.id);
                            if (data.id > 0) {
                                setUpdate(true);
                                setBtnText('Güncelle');
                            }
                        }
                    } else {
                        const { data } = await axios.get(`employee/work-schedules?filter_user_id=${props.user.id}&filter_working_date=${working_date}`);
                        setDefinition(data.work_schedule?.data?.data[0]?.definition);
                        setUpdateId(data.work_schedule?.data?.data[0]?.id);
                        if (data.work_schedule?.data?.data[0]?.id > 0) {
                            setUpdate(true);
                            setBtnText('Güncelle');
                        }
                    }
                }
            )();
            setGetDataStatus(false);
        }
    }, [get_data_status]);


    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");

    const isEditing = (record: Item) => record.key === editingKey;


    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Günlük İş Raporları', active: true },
    ];
    const modalLoaded = () => {
    };

    function isTodayOrYesterday(date: Date): boolean {
        const today = new Date();
        const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        return date.toDateString() === today.toDateString() || date.toDateString() === yesterday.toDateString();
    }
    return (
        <Wrapper>
            <Modal show={show} onHide={handleClose} onEntered={modalLoaded} size="lg" animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{user_full_name} - Açıklama</Modal.Title>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{__html: definition}} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Kapat
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Personel Günlük İş Raporları</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 mb-3">
                                    <div className="form-group select-style">
                                        <label className="d-block">Tarih Aralığı</label>
                                        <RangePicker
                                            format={'DD/MM/YYYY'}
                                            locale={locale}
                                            ranges={rangePresets}
                                            value={date_range}
                                            style={{ width: '100%' }}
                                            onChange={(value, dateString) => {
                                                setDateRange(value);
                                                setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setSendFilter(true);
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>Kategori</label>
                                        <Select className="form-control" defaultValue={category_id} showSearch
                                            value={category_id}
                                            allowClear={true}
                                            placeholder="Kategori Seçiniz"
                                            onChange={e => { setCategoryId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                        >
                                            {user_categories?.map((user_categories: Category) => {
                                                return (
                                                    <Option value={user_categories.id} title={user_categories.name}
                                                        key={user_categories.name}
                                                        id={user_categories.name}>{user_categories.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group mb-3 select-style">
                                        <label>Personel(ler)</label>
                                        <Select className="form-control" showSearch
                                            allowClear={true}
                                            onChange={e => { setSelectedEmployees(e); setFilter(true); }}
                                            value={selected_employees}
                                            defaultValue={selected_employees}
                                            mode="multiple"
                                            placeholder="Personel Seçiniz"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {users.map((users: User) => {
                                                return (
                                                    <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                        key={users.first_name + ' ' + users.last_name}
                                                        id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="float-end">
                                    <button type="button" onClick={e => setClearFilterInputs(true)} className="btn btn-soft-light btn-sm" >Temizle</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridWeek"
                                editable={false}
                                droppable={false}
                                locale={trLocale}
                                eventClick={(e: any) => setHandleShow(e)}
                                headerToolbar={{
                                    left: 'prev,next',
                                    center: 'title',
                                    right: 'dayGridMonth,dayGridWeek'
                                }}
                                events={
                                    watchArrdata
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default connect(
    (state: { user: User }) => {
        return {
            user: state.user
        };
    }
)(WorkSchedule);
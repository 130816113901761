import React, { Component, Dispatch, useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import axios from "axios";
import Header from "./header";
import Menu from "./menu";
import { connect } from "react-redux";
import { User } from "../../models/user";
import { setUser } from "../../redux/actions/setUserAction";
import { setMenu } from "../../redux/actions/setMenuAction";
import Footer from "./footer";
import { Module } from "../../models/module";
import OneSignal from 'react-onesignal';
import { Role } from "../../models/role";

const Wrapper = (props: any) => {
  const [redirect, setRedirect] = useState(false);
  const [notificationSendStatus, setNotificationSendStatus] = useState(false);

  useEffect(() => {
    (
      async () => {
        try {
          const { data } = await axios.get('user');

          let role: Role;
          if (typeof data.role_id === "object") {
            role = new Role(
              data.role_id.id,
              data.role_id.name
            );
          } else {
            role = new Role(data.role_id);
          }

          const user = new User(
            data.id,
            data.first_name,
            data.last_name,
            data.email,
            data.role_id,
            data.role.id,
            data.role.name
          );
          user.role = data.role;

          props.setUser(user);

          if (data.id > 0) {
            OneSignal.init({
              appId: "9823a041-d6fc-4842-a220-74abf5bbfb48"
            });

            OneSignal.on("subscriptionChange", (isSubscribed) => {
              if (isSubscribed) {
                OneSignal.getUserId().then((notification_id) => {
                  console.log("User ID:", notification_id);
                  if (!notificationSendStatus) {
                    axios.post('notifications-subscriptions', {
                      notification_id
                    });
                  }
                  setNotificationSendStatus(true);
                });
              }
            });





  //           const headers = {
  //             "Content-Type": "application/json; charset=utf-8",
  //             "Authorization": "Basic YjgzOWI4NGQtMTQ1ZC00ZTFmLTk4MmYtNDg4YjVkYmZhNTZj"
  //           };
            
  //           const data = {
  //             app_id: "9823a041-d6fc-4842-a220-74abf5bbfb48",
  //             include_player_ids: ["59cb20b0-4aff-4665-a08f-c3a3197f6dd6"],
  //             contents: { en: "Notification Message" },
  //             headings: { en: "Notification Title" }
  //           };
            
  //           await axios.post("https://onesignal.com/api/v1/notifications", data, { headers })
  // .then(response => {
  //   console.log(response.data);
  // })
  // .catch(error => {
  //   console.log(error);
  // });


          }
        } catch (e) {
          console.log(e);
          setRedirect(true);
        }
      }
    )();
  }, [props.setUser]);




  // useEffect(() => {
  //     (
  //         async () => {
  //             const {menu}:any = await axios.get(`modules-menu/1`);

  //             props.setMenu(new Module(
  //                 menu.id,
  //                 menu.name,
  //                 menu.icon,
  //                 menu.slug
  //             ));

  //         }
  //     )();

  // }, []);
  if (redirect) {
    return <Navigate to={'/login'} />;
  }
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Menu />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {props.children}
            </div>
          </div>
          <Footer />

        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: { user: User, menu: Module }) => {
  return {
    user: state.user,
    menu: state.menu
  };
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUser: (user: User) => dispatch(setUser(user)),
    setMenu: (menu: Module) => dispatch(setMenu(menu))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);



import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { CustomerType } from '../../../models/attribute/customerType/customerType';
import { CustomerTypeContext } from '../../../contexts/attribute/customerType/customerTypeContext';

export const CustomerTypeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [customer_types, setCustomerTypes] = useState<CustomerType[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getCustomerTypes() {
        try {
          const { data } = await axios.get('attributes/5');
          if (isMounted) {
            setCustomerTypes(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      getCustomerTypes();
  
      return () => {
        isMounted = false;
      };
  }, []);


  return (
      <CustomerTypeContext.Provider value={[customer_types, setCustomerTypes]}>
          {children}
      </CustomerTypeContext.Provider>
  );
}

export const useCustomerTypeContext = () => useContext(CustomerTypeContext);

import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import { User } from "../../../models/user";
import { Call } from "../../../models/asistant/call/call";
import { WatchSchedules } from "../../../models/asistant/watchSchedule/watchSchedule";
import { WatchScheduleActions } from "../../../models/asistant/watchSchedule/watchScheduleAction";
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import Moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import trLocale from '@fullcalendar/core/locales/tr';

import { FiTrash, FiEdit2, FiCheck } from 'react-icons/fi';
import { BsCheckLg } from 'react-icons/bs';

import { Table, InputNumber, Popconfirm, Form, Select, Radio, Tag,message } from "antd";

import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { CustomerType } from "../../../models/attribute/customerType/customerType";
import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import { Contact } from "../../../models/attribute/contact/contact";
import { Status } from "../../../models/attribute/status/status";
import { IdType } from "../../../models/attribute/idType/idType";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { PatternFormat } from "react-number-format";
import { Country } from "../../../models/general/country/country";
import { PlusOutlined } from "@ant-design/icons";
import { Attribute } from "../../../models/attribute/attribute";
import CustomInput from "../../../components/form/customInput";
import isMobile from "../../../components/constant/isMobile";
import Tel from "../../../components/link/tel";
import Mailto from "../../../components/link/mailto";
import WatchScheduleDetail from "../../../components/watch-schedule/detail";
import moment from "moment";

interface Item {
    key: string;
    id: number;
    name: string;
    phone: string;
    email: string;
    customer_type: string;
    property_type: string;
    property_genus: string;
    contact_id: string;
    meet_time: string;

}


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: Item;
    index: number;
    children: React.ReactNode;
}


const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === "number";

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Lüfen ${title} alanını doldurunuz!`
                        }
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


const WatchSchedule = () => {

    const [actions, setActions] = useState([]);
    const [actionRef, setActionRef] = useState(0);
    const [getAction, setGetAction] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();

    // Customer
    const [customer_type_id, setCustomerTypeId] = useState(1);
    const [customer_application_type_id, setCustomerApplicationTypeId] = useState(0);
    const [company_name, setCompanyName] = useState('');
    const [tax_admin, setTaxAdmin] = useState('');
    const [tax_number, setTaxNumber] = useState('');
    const [phone_code_id, setPhoneCodeId] = useState(34);
    const [first_name, setFirstName] = useState<any | null>('');
    const [last_name, setLastName] = useState<any | null>('');
    const [id_type_id, setIdTypeId] = useState(3);
    const [nationality_id, setNationalityId] = useState(34);
    const [id_no, setIdNo] = useState('');
    const [birthday, setBirthday] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [note, setNote] = useState('');

    //Property
    const [property_genus_id, setPropertyGenusId] = useState<any | null>(7);
    const [property_type_id, setPropertyTypeId] = useState<any | null>(14);
    const [contact_type_id, setContactTypeId] = useState<any | null>(0);

    const [property_genuses, setPropertyGenuses] = useState([]);
    const [property_types, setPropertyTypes] = useState([]);

    const [customer_types, setCustomerTypes] = useState([]);
    const [id_types, setIdTypes] = useState([]);
    const [phone_codes, setPhoneCodes] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [note_see, setNoteSee] = useState(false);

    const [customer_application_types, setCustomerApplicationTypes] = useState([]);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/6');

                setIdTypes(data.data);
            }
        )()
    }, []);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/5');

                setCustomerTypes(data.data);
            }
        )()
    }, []);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/39');

                setCustomerApplicationTypes(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('general/countries');

                setPhoneCodes(data);
                setNationalities(data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/7');

                setPropertyGenuses(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/8/' + property_genus_id);

                setPropertyTypes(data.data);
            }
        )()
    }, [property_genus_id]);

    useEffect(() => {
        (
            async () => {
                if (actionRef == 1) {
                    const { data } = await axios.get(`asistant/watch-schedule-action/${getAction}/${meet_date}`);
                    setActions(data);
                }
                setActionRef(0);
            }
        )()
    }, [actionRef]);

    const originData: any[] = [];
    {
        actions.map((actions: WatchScheduleActions) => {
            originData.push({
                key: actions.id.toString(),
                id: actions.id,
                name: `${actions.customer?.first_name} ${actions.customer?.last_name}`,
                phone: `+${actions.customer?.phoneCode?.phone_code} ${actions.customer?.phone}`,
                email: actions.customer?.email,
                customer_type: actions.customerApplicationType?.name,
                property_type: actions.propertyType?.name,
                property_genus: actions.propertyGenus?.name,
                contact_id: actions?.contact?.name,
                status_id: actions?.status?.name,
                status_color: actions?.status?.color,
                meet_time: actions?.meet_time,
            });
        }
        )
    }
    const [calls, setWatchSchedule] = useState([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [ref, setRef] = useState(1);

    //Detail Modal
    const [selectedDate, setSelectedDate] = useState('');


    //Modal add watch schedule
    const [schedule_date, setScheduleDate] = useState('');
    const [selectedName, setSelectedName] = useState('');

    const { Option } = Select;

    const [user_id, setUserID] = useState(0);

    const handleChange = (e: any) => { setUserID(e); };

    const [users, setUsers] = useState([]);
    const [watchSchedules, setWatchSchedules] = useState([]);

    const [metas, setCallCount] = useState([0]);
    const handleRemoveItem = (item: any) => {
        setCallCount(metas.filter(item => item !== item));
    };

    const startOfPrevMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    const endOfNextMonth = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');

    useEffect(() => {
        (
            async () => {
                if(ref===1){
                const { data } = await axios.get(`asistant/watch-schedules?limit=1000&page=1&filter_between[]=${startOfPrevMonth}&filter_between[]=${endOfNextMonth}`);
                setWatchSchedules(data.watch_schedule.data.data);
            }
                setRef(0);
            }
        )()
    }, [ref]);

    var watchData = [] as any;
    var watchArrdata = [] as any;

    {
        watchSchedules.map((w: WatchSchedules) => {
            watchData['id'] = w.id;
            watchData['agent_id'] = w.user.id;
            watchData['date'] = w.schedule_date;
            
            const actionCount = Array.isArray(w.action) ? Object.keys(w.action).length : 0;
            
            watchData['title'] = isMobile ? `${w.user?.first_name.charAt(0)}${w.user?.last_name.charAt(0)}` : `${w.user?.first_name} ${w.user?.last_name} (${actionCount})`;
            watchData['full_name'] =`${w.user?.first_name} ${w.user?.last_name} (${actionCount})`;
            watchArrdata.push({ ...watchData });
        })

    }

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('users');

                setUsers(data.data);
            }
        )()
    }, []);

    var usrOpt = [] as any;
    var usrArrOpt = [] as any;

    {
        users.map((u: User) => {
            usrOpt['value'] = u.id;
            usrOpt['label'] = u.first_name + " " + u.last_name;
            usrArrOpt.push({ ...usrOpt });
        })
    }


    //Modal    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = (e: any) => {
        (
            () => {
                setShow(true);
                setSelectedDate(Moment(e.dateStr).format("DD.MM.YYYY"));
                setScheduleDate(Moment(e.dateStr).format("YYYY-MM-DD"));


            }
        )()
    };

        //Customer Detail Modal    
        const [show_customer_detail, setCustomerDetailShow] = useState(false);
        const handleCustomerDetailClose = () => {setCustomerDetailShow(false); setShowDetail(true);}
        const [show_customer_detail_id, setShowCustomerDetailID] = useState<any | null>(0);
        const [show_customer_full_name, setShowCustomerFullName] = useState<string | any>('');

        const handleCustomerDetailShow = (e: any) => {
            (
                () => {
                    setCustomerDetailShow(true);
                }
            )()
        };
    

    // Detail Modal
    const [showDetail, setShowDetail] = useState(false);
    const handleDetailClose = () => setShowDetail(false);


    const [ws_id, setWsId] = useState(0);
    const [definition, setDefinition] = useState('');
    const [customer_type, setCustomerType] = useState(0);
    const [property_type, setPropertyType] = useState(0);
    const [property_genus, setPropertyGenus] = useState(0);
    const [contact_id, setContactId] = useState(0);
    const [status_id, setStatusId] = useState(169);
    const [meet_date, setMeetDate] = useState('');
    const [meet_time, setMeetTime] = useState('');
    const [agent_id, setAgentId] = useState(0);

    const handleShowDetail = (e: any, sDate: any) => {
        (
            () => {
                setShowDetail(true);
                setSelectedName(e.extendedProps?.full_name);
                setAgentId(e.extendedProps.agent_id);
                setWsId(e.publicId)
                setSelectedDate(Moment(sDate).format("DD.MM.YYYY"));
                setMeetDate(Moment(sDate).format("YYYY-MM-DD"));
                setGetAction(e.extendedProps.agent_id);
                setActionRef(1);
            }
        )()
    };
    const modalLoaded = () => {
    };
    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (user_id > 0) {
            setShow(false);
            await axios.post('asistant/watch-schedules', {
                user_id,
                schedule_date
            });
            setRef(1);
        } else {
            message.warning('Lütfen bir danışman seçiniz.');

    }
}


    // Add New Modal
    const [showAdd, setShowAdd] = useState(false);
    const handleAddClose = () => setShowAdd(false);

    const handleAdd = () => {
        (
            () => {
                setShowAdd(true);
                setMeetTime(Moment().format('HH:mm'));
            }
        )()
    };


    const [contacts, setContacts] = useState([]);
    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/36');

                setContacts(data.data);
            }
        )()
    }, []);


    const [statuses, setStatuses] = useState([]);
    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/37');

                setStatuses(data.data);
            }
        )()
    }, []);

    const [contact_type, setContractType] = useState([]);
    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/38');

                setContractType(data.data);
            }
        )()
    }, []);





    const submitAdd = async (e: SyntheticEvent) => {
        e.preventDefault(); 
        setShowAdd(false);
        await axios.post('asistant/watch-schedule-actions', {
            first_name,
            last_name,
            id_type_id,
            id_no,
            birthday,
            address,
            note,
            ws_id,
            email,
            phone,
            phone_code_id,
            definition,
            customer_type_id,
            property_type_id,
            property_genus_id,
            contact_id,
            status_id,
            meet_date,
            meet_time,
            contact_type_id,
            customer_application_type_id,
            agent_id
        });
        setActionRef(1);
    }

    const del = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Seçilen gün için kullanıcı ve kullanıcının tüm yaptığı işlemler kalıcı olarak silinecektir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`asistant/watch-schedules/${id}`);

                setWatchSchedules(watchSchedules.filter((u: WatchSchedules) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                });
                setRef(1);
                setShowDetail(false);            
            }
        })
    }


    const delAction = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Bu müşteriye ait tüm bilgiler kalıcı olarak silinecetir.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`asistant/watch-schedule-actions/${id}`);

                setActions(actions.filter((u: WatchScheduleActions) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                });
                setActionRef(1);
            }
        })
    }


    const [form] = Form.useForm();
    const [data, setData] = useState(originData);
    const [editingKey, setEditingKey] = useState("");

    const isEditing = (record: Item) => record.key === editingKey;

    const edit = (record: Item) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as Item;

            const newData = [...data];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row
                });
                setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            sorter: (a: { id: number; }, b: { id: number; }) => a.id - b.id,
            responsive: ["md", "lg", "xl", "xxl"],
        },
        {
            title: "Ad Soyad",
            dataIndex: "name",
            key: "name",
            render: (name:string, record:any) => {

                return (
                    <>
                        <a onClick={() => { setCustomerDetailShow(true); setShowCustomerDetailID(record.id); setShowCustomerFullName(`${name}`); setShowDetail(false); }}>
                            {name}
                        </a>
                    </>
                );
            },
            editable: true
        },
        {
            title: "Telefon",
            dataIndex: "phone",
            render: (phone:string|number) => {
                return (
                    <>
                       <Tel phone={phone}>{phone}</Tel>
                    </>
                );
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            responsive: ["md", "lg", "xl", "xxl"],
            render: (email:string|number)=>{
                return(
                    <>
                    <Mailto email={email} body="Merhaba">{email}</Mailto>
                    </>
                );
            },
            editable: true
        },
        {
            title: "Müşteri Türü",
            dataIndex: "customer_type",
            key: "customer_type",
            sorter: (a: { customer_type: string; }, b: { customer_type: any; }) => a.customer_type.localeCompare(b.customer_type),
            editable: true
        },
        {
            title: "İlan Türü",
            dataIndex: "property_type",
            sorter: (a: { property_type: string; }, b: { property_type: any; }) => a.property_type.localeCompare(b.property_type),
            responsive: ["md", "lg", "xl", "xxl"],
            editable: true
        },
        {
            title: "İlan Cinsi",
            dataIndex: "property_genus",
            sorter: (a: { property_genus: string; }, b: { property_genus: any; }) => a.property_genus.localeCompare(b.property_genus),
            responsive: ["md", "lg", "xl", "xxl"],
            editable: true
        },
        {
            title: "İletişim Kaynağı",
            dataIndex: "contact_id",
            sorter: (a: { contact_id: string; }, b: { contact_id: any; }) => a.contact_id.localeCompare(b.contact_id),
            responsive: ["md", "lg", "xl", "xxl"],
            editable: true
        },
        {
            title: "Durum",
            dataIndex: "status_id",
            sorter: (a: { status_id: any }, b: { status_id: any  }) => a.status_id.localeCompare(b.status_id),
            responsive: ["md", "lg", "xl", "xxl"],
            render: (status_id:string,record:any) => (
                <>
                    <Tag color={record.status_color}>{status_id}</Tag>
                </>
            ),
            editable: true
        },
        {
            title: "Görüşme Saati",
            dataIndex: "meet_time",
            sorter: (a: { meet_time: string; }, b: { meet_time: any; }) => a.meet_time.localeCompare(b.meet_time),
            responsive: ["md", "lg", "xl", "xxl"],
            editable: true
        },
        {
            title: "",
            dataIndex: "operation",
            render: (_: any, record: Item) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a
                            className="text-primary"
                            onClick={() => save(record.key)}
                            style={{ marginRight: 8 }}
                        >
                            <BsCheckLg />
                        </a>
                        <a className="ms-2 text-danger">
                            {/* <Popconfirm  placement="leftTop" onConfirm={cancel} okText="Evet" cancelText="Hayır" title="İşlemi iptal etmek istediğinize emin misiniz? Yaptığınız tüm değişiklikler kayıt edilmeyecektir." onConfirm={cancel}>
                            <FaTimes/>
                        </Popconfirm> */}
                        </a>
                    </span>
                ) : (
                    <>
                        {/* <a
                            onClick={() => edit(record)}
                        >
                            <FiEdit2 className="text-primary" />
                        </a> */}
                        <a onClick={() => delAction(record.id)}
                            className="ms-2"
                        >
                                <FiTrash className="text-danger" />
                        </a>
                    </>
                );
            }
        }
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType: col.dataIndex === "age" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    return (
        <Wrapper>
            <Modal show={show} onHide={handleClose} onEntered={modalLoaded} size="sm" animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedDate}</Modal.Title>
                </Modal.Header>
                <form onSubmit={submit}>
                    <Modal.Body>
                    {contextHolder}

                        <Select
                            style={{ width: "calc(100%)" }}
                            autoFocus
                            showSearch
                            placeholder="Nöbetçi Seçiniz"
                            optionFilterProp="children"
                            dropdownStyle={{ zIndex: 2000 }}
                            onChange={handleChange}
                            className="w100"
                            notFoundContent="Sonuç bulunamadı."
                            allowClear

                        >
                            {users.map((u: User) => {
                                return (
                                    <Option key={u.id} value={u.id} >{u.first_name} {u.last_name}</Option>
                                )
                            })}
                        </Select>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            İptal
                        </Button>
                        <button type="submit" className="btn btn-primary">Uygula</button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showDetail} onHide={handleDetailClose} onEntered={modalLoaded} size="xl" animation={false} dialogClassName="modal-h" contentClassName="modal-h" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedName} - {selectedDate}</Modal.Title>
                </Modal.Header>
                <form onSubmit={submit}>
                    <Modal.Body>
                        <Form form={form} component={false}>
                            <Button
                                onClick={handleAdd}
                                variant="light"
                                style={{
                                    marginBottom: 16,
                                }}
                            >
                                <i className="bx bx-plus me-1"></i>
                                Yeni Ekle
                            </Button>
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell
                                    }
                                }}
                                bordered
                                dataSource={originData}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                                pagination={{
                                    onChange: cancel
                                }}
                            />
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => del(ws_id)}>
                            Sil
                        </Button>
                        <Button variant="secondary" onClick={handleDetailClose}>
                            İptal
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* Add New */}
            <Modal show={showAdd} onHide={handleAddClose} onEntered={modalLoaded} animation={true} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Yeni Görüşme Ekle</Modal.Title>
                </Modal.Header>
                <form onSubmit={submitAdd}>
                    <Modal.Body>
                        <form onSubmit={submit}>
                            {/* Müşteri Bilgileri */}
                            <div className="card">
                                <div className="card-body p-4">
                                    <div>
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12">
                                                <div className="form-group mb-3">
                                                    <label>Müşteri Bilgileri </label>
                                                    <div>
                                                        <Radio.Group defaultValue={customer_type_id} onChange={e => setCustomerTypeId(e.target.value)}>
                                                            {customer_types.map((customer_types: CustomerType) => {
                                                                return (
                                                                    <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                                )
                                                            })}
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-md-6" style={customer_type_id == 2 ? {} : { display: 'none' }} >
                                                <div className="form-group mb-3">
                                                    <label>Firma Adı</label>
                                                    <CustomInput
                                                        onChange={(e: any) => setCompanyName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3" style={customer_type_id == 2 ? {} : { display: 'none' }} >
                                                <div className="form-group mb-3">
                                                    <label>Vergi Dairesi</label>
                                                    <CustomInput
                                                        onChange={(e: any) => setTaxAdmin(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3" style={customer_type_id == 2 ? {} : { display: 'none' }} >
                                                <div className="form-group mb-3">
                                                    <label>Vergi Numarası</label>
                                                    <CustomInput
                                                        onChange={(e: any) => setTaxNumber(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Ad</label>
                                                    <CustomInput
                                                        required={true}
                                                        onChange={(e: any) => setFirstName(e.target.value)}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Soyad</label>
                                                    <CustomInput
                                                        required={true}
                                                        onChange={(e: any) => setLastName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3">
                                                <div className="form-group mb-3 select-style">
                                                    <label>Kimlik Tipi</label>
                                                    <Select className="form-control" defaultValue={3} showSearch
                                                        optionFilterProp="children"
                                                        onChange={e => setIdTypeId(e)}
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >

                                                        {id_types.map((id_types: IdType) => {
                                                            return (
                                                                <Option value={id_types.id} title={id_types.name}
                                                                    key={id_types.name}
                                                                    id={id_types.name}>{id_types.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Numara</label>
                                                    <CustomInput onChange={(e: any) => setIdNo(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Telefon</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text p-0">
                                                            <Select defaultValue={phone_code_id} style={{ width: 100 }} showSearch
                                                                optionFilterProp="children"
                                                                onChange={e => setPhoneCodeId(e)}
                                                                filterOption={(input, option) => {
                                                                    return (
                                                                        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                        option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    );

                                                                }}
                                                            >

                                                                {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                    let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                    return (
                                                                        <Option value={phone_codes.id} title={phone_codes.name}
                                                                            key={phone_codes.name}
                                                                            id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                    )
                                                                })}
                                                            </Select>

                                                        </div>
                                                        <PatternFormat format="(###) ### ## ##" name="phone" required className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setPhone(e.target.value)} />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Email</label>
                                                    <CustomInput type={'email'} onChange={(e: any) => setEmail(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3">
                                                <div className="form-group mb-3 select-style">
                                                    <label>Uyruk</label>
                                                    <Select className="form-control" defaultValue={nationality_id} showSearch
                                                        optionFilterProp="children"
                                                        onChange={e => setNationalityId(e)}
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >

                                                        {nationalities?.map((nationalities: Country) => {
                                                            return (
                                                                <Option value={nationalities.id} title={nationalities.name}
                                                                    key={nationalities.name}
                                                                    id={nationalities.name}>{nationalities.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Doğum Tarihi</label>
                                                    <CustomInput type={'date'} onChange={(e: any) => setBirthday(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12">
                                                <div className="form-group mb-3">
                                                    <label>Adres</label>
                                                    <CustomInput onChange={(e: any) => setAddress(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12">
                                                <div className="form-group mb-3">
                                                    <button type="button" onClick={e => { note_see ? setNoteSee(false) : setNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12" style={note_see ? {} : { display: 'none' }}>
                                                <div className="form-group mb-3">
                                                    <label>Not</label>
                                                    <CustomInput type={'textarea'} onChange={(e: any) => setNote(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Diğer Bilgiler */}
                            <div className="card">
                                <div className="card-body p-4">
                                    <div>
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12">
                                                <div className="form-group mb-3">
                                                    <label>Diğer Bilgiler</label>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12">
                                                <div className="form-group mb-3">
                                                    <label>Müşteri Türü </label>
                                                    <div>
                                                        <Radio.Group onChange={e => setCustomerApplicationTypeId(e.target.value)}>
                                                            {customer_application_types.map((customer_application_types: CustomerType) => {
                                                                return (
                                                                    <Radio value={customer_application_types.id} >{customer_application_types.name}</Radio>
                                                                )
                                                            })}
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-md-2">
                                                <div className="form-group mb-3 select-style">
                                                    <label>Gayrimenkul Cinsi</label>
                                                    <Select className="form-control" defaultValue={property_genus_id} showSearch
                                                        optionFilterProp="children"
                                                        onChange={e => { setPropertyGenusId(e); setPropertyTypeId(null); }}
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >
                                                        {property_genuses?.map((property_genuses: Attribute) => {
                                                            return (
                                                                <Option value={property_genuses.id} title={property_genuses.name}
                                                                    key={property_genuses.name}
                                                                    id={property_genuses.name}>{property_genuses.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-md-2" style={property_genus_id == 10 ? { display: 'none' } : {}} >
                                                <div className="form-group mb-3 select-style">
                                                    <label>Gayrimenkul Türü</label>
                                                    <Select className="form-control" defaultValue={property_type_id} showSearch
                                                        value={property_type_id}
                                                        placeholder="Gayrimenkul Türü Seçiniz"
                                                        onChange={e => setPropertyTypeId(e)}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >

                                                        {property_types.map((property_types: PropertyType) => {
                                                            return (
                                                                <Option value={property_types.id} title={property_types.name}
                                                                    key={property_types.name}
                                                                    id={property_types.name}>{property_types.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-md-2">
                                                <div className="form-group mb-3 select-style">
                                                    <label>İletişim Tipi</label>
                                                    <Select
                                                        className="form-control"                                                        
                                                        showSearch
                                                        placeholder="Seçiniz"
                                                        optionFilterProp="children"
                                                        dropdownStyle={{ zIndex: 2000 }}
                                                        onChange={e => setContactTypeId(e)}                                                        
                                                        notFoundContent="Sonuç bulunamadı."
                                                    >
                                                        {contact_type.map((c: Contact) => {
                                                            return (
                                                                <Option key={c.id} value={c.id} >{c.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-md-2">
                                                <div className="form-group mb-3 select-style">
                                                    <label>İletişim Kaynağı</label>
                                                    <Select
                                                        style={{ width: "calc(100%)" }}
                                                        autoFocus
                                                        showSearch
                                                        placeholder="Seçiniz"
                                                        optionFilterProp="children"
                                                        dropdownStyle={{ zIndex: 2000 }}
                                                        onChange={e => setContactId(e)}
                                                        className="form-control"
                                                        notFoundContent="Sonuç bulunamadı."
                                                    >
                                                        {contacts.map((c: Contact) => {
                                                            return (
                                                                <Option key={c.id} value={c.id} >{c.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-md-2">
                                                <div className="form-group mb-3">
                                                    <label>Görüşme Saati</label>
                                                    <CustomInput type={'time'} onChange={(e: any) => setMeetTime(e.target.value)} value={meet_time} />
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-md-2">
                                                <div className="form-group mb-3 select-style">
                                                    <label>Durum</label>
                                                    <Select
                                                        style={{ width: "calc(100%)" }}
                                                        req
                                                        showSearch
                                                        placeholder="Seçiniz"
                                                        optionFilterProp="children"
                                                        dropdownStyle={{ zIndex: 2000 }}
                                                        onChange={e => setStatusId(e)}
                                                        className="form-control"
                                                        notFoundContent="Sonuç bulunamadı."
                                                        required
                                                    >
                                                        {statuses.map((s: Status) => {
                                                            return (
                                                                <Option key={s.id} value={s.id} >{s.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-md-12">
                                                <div className="form-group mb-3">
                                                    <label>Açıklama</label>
                                                    <CustomInput type={'textarea'} rows={3} onChange={(e: any) => setDefinition(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAddClose}>
                            İptal
                        </Button>
                        <button type="submit" className="btn btn-primary">Kaydet</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Nöbet Çizelgesi</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item active">Nöbet Çizelgesi</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                editable={false}
                                droppable={false}
                                locale={trLocale}
                                dateClick={handleShow}
                                eventClick={
                                    function (e) {
                                        handleShowDetail(e.event._def, e.event._instance?.range.start);
                                    }
                                }
                                headerToolbar={{
                                    left: 'prev,next',
                                    center: 'title',
                                    right: 'dayGridMonth,dayGridWeek'
                                }}
                                events={
                                    watchArrdata
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show_customer_detail} onHide={handleCustomerDetailClose} size="xl" animation={false} centered>
                {show_customer_detail_id && show_customer_detail_id !== null && show_customer_detail_id !== undefined &&
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title><span className="ms-2">{show_customer_full_name}</span> - <span className="text-danger">{show_customer_detail_id}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <WatchScheduleDetail id={show_customer_detail_id} />
                        </Modal.Body><Modal.Footer>
                            <Button variant="secondary" onClick={handleCustomerDetailClose}>
                                Kapat
                            </Button>
                            <Button variant="primary" onClick={handleCustomerDetailClose}>
                                Güncelle
                            </Button>
                        </Modal.Footer>
                    </>
                }
            </Modal>
        </Wrapper>
    )
}

export default WatchSchedule;
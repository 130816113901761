
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { GiroType } from '../../../models/attribute/giroType/giroType';
import { GiroTypeContext } from '../../../contexts/attribute/giroType/giroTypeContext';

export const GiroTypeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [giro_types, setGiroTypes] = useState<GiroType[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getGiroTypes() {
        try {
          const { data } = await axios.get('attributes/11');
          if (isMounted) {
            setGiroTypes(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      getGiroTypes();
  
      return () => {
        isMounted = false;
      };
  }, []);


  return (
      <GiroTypeContext.Provider value={[giro_types, setGiroTypes]}>
          {children}
      </GiroTypeContext.Provider>
  );
}

export const useGiroTypeContext = () => useContext(GiroTypeContext);

import React, { SyntheticEvent, useState, useEffect } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import { Lock, Mail } from 'react-feather';
import { message } from 'antd';


const Login = () => {

    const [password_input_type, setPasswordInputType] = useState<'password' | 'text'>('password');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    const [disable_button, setDisableButton] = useState(false);
    const [login_button_text, setLoginButtonText] = useState('Giriş');

    const toggleInputType = () => {
        setPasswordInputType(password_input_type === 'password' ? 'text' : 'password');
      };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        setLoginButtonText('Bekleyiniz...');
        setDisableButton(true);
        const data = await axios.post('login', {
            email,
            password
        }).then(response => {
            setRedirect(true);
        })
            .catch(error => {
                messageApi.open({
                    type: 'error',
                    content: 'Kullanıcı adınız veya şifreniz hatalı.',
                });
                setLoginButtonText('Giriş');
                setDisableButton(false);
            });
    }

    useEffect(() => {
        (
            async () => {
                try {
                    const { data } = await axios.get('user');
                    setRedirect(true);
                } catch (e) {
                }

            }
        )();

    }, []);

    if (redirect) {
        return <Navigate to={'/'} />;
    }
    return (
        <div className="auth-page">
            <Container fluid className="p-0">
                <Row className="g-0">
                    <Col xxl={3} lg={4} md={5}>
                        <div className="auth-full-page-content d-flex p-sm-5 p-4">
                            <div className="w-100">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-4 mb-md-5 text-center">
                                        <a href="/" className="d-block auth-logo">
                                            <img src="assets/images/logo-sm.svg" alt="" height="28" /> <span className="logo-txt">LLUPAR</span>
                                        </a>
                                    </div>
                                    <div className="auth-content my-auto">
                                        <div className="text-center">
                                            <h5 className="mb-0">Hoş Geldiniz</h5>
                                            <p className="text-muted mt-2">Devam etmek için oturum açınız</p>
                                        </div>

                                        <div className="mt-4 pt-2">
                                            <form onSubmit={submit}>
                                                <div className="form-floating form-floating-custom mb-4">
                                                    <input className="form-control" id="input-username" placeholder="Kullanıcı adınızı giriniz"
                                                        autoComplete="off"
                                                        type="email"
                                                        required
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                    <label htmlFor="input-username">Kullanıcı Adı</label>
                                                    <div className="form-floating-icon">
                                                        <Mail />
                                                    </div>
                                                </div>

                                                <div className="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                                                    <input type={password_input_type} required className="form-control pe-5" id="password-input" placeholder="Şifrenizi giriniz"
                                                        onChange={e => setPassword(e.target.value)}
                                                    />

                                                    <button type="button" onClick={toggleInputType} className="btn btn-link position-absolute h-100 end-0 top-0" id="password-addon">
                                                        <i className="mdi mdi-eye-outline font-size-18 text-muted"></i>
                                                    </button>
                                                    <label htmlFor="input-password">Şifre</label>
                                                    <div className="form-floating-icon">
                                                        <Lock />
                                                    </div>
                                                </div>

                                                <div className="row mb-4">
                                                    <div className="col">
                                                        <div className="form-check font-size-15">
                                                            <input className="form-check-input" type="checkbox" id="remember-check" />
                                                            <label className="form-check-label font-size-13" htmlFor="remember-check">
                                                                Beni hatırla
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="mb-3">
                                                    <button
                                                        className="btn btn-primary w-100 waves-effect waves-light" type="submit" disabled={disable_button}>{login_button_text}</button>
                                                </div>
                                                {contextHolder}
                                            </form>
                                        </div>

                                        <div className="mt-4 pt-2 text-center">
                                            <div className="signin-other-title">
                                                <h5 className="font-size-14 mb-3 text-muted fw-medium">- Veya -</h5>
                                            </div>

                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item">
                                                    <a href="javascript:void()"
                                                        className="btn bg-dark text-white border-dark">
                                                        <i className="mdi mdi-apple"></i> Apple ile Giriş
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="javascript:void()"
                                                        className="btn btn-block bg-danger text-white border-danger">
                                                        <i className="mdi mdi-google"></i> Google ile Giriş
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mt-5 text-center">
                                            <p className="text-muted mb-0">
                                                <Link to="/forgot-password" className="text-primary fw-semibold">Şifremi unuttum</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-4 mt-md-5 text-center">
                                        <p className="mb-0">© <script>document.write(new Date().getFullYear())</script> LLUPAR   . Crafted with <i className="mdi mdi-heart text-danger"></i> by <a href="https://rapull.com" target={'_blank'}><img src="/rapull.svg" alt="Rapull Soft" style={{ height: '15px' }}/></a></p>
                                        <p className="mb-0 mt-2">{localStorage.getItem('ver')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={9} lg={8} md={7}>
                        <div className="auth-bg pt-md-5 p-4 d-flex">
                            <div className="bg-overlay"></div>
                            <ul className="bg-bubbles">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default Login;


import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { LandOffice } from '../../../../models/general/location/landOffice/landOffice';
import { LandOfficeContext } from '../../../../contexts/general/location/landOffice/landOfficeContext';


export const LandOfficeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [land_offices, setLandOffices] = useState<LandOffice[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getDatas() {
        try {
          const { data } = await axios.get('location/land-offices');
          if (isMounted) {
            setLandOffices(data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      getDatas();
  
      return () => {
        isMounted = false;
      };
  }, []);


  return (
      <LandOfficeContext.Provider value={[land_offices, setLandOffices]}>
          {children}
      </LandOfficeContext.Provider>
  );
}

export const useLandOfficeContext = () => useContext(LandOfficeContext);

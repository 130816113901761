
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { PaymentMethodContext } from '../../../contexts/attribute/paymentMethod/paymentMethodContext';
import { PaymentMethod } from '../../../models/attribute/paymentMethod/paymentMethod';
import { Attribute } from '../../../models/attribute/attribute';

export const PaymentMethodProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [payment_methods, setPaymentMethods] = useState<Attribute[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getPaymentMethods() {
        try {
          const { data } = await axios.get('attributes/13');
          if (isMounted) {
            setPaymentMethods(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      getPaymentMethods();
  
      return () => {
        isMounted = false;
      };
  }, []);


  return (
      <PaymentMethodContext.Provider value={[payment_methods, setPaymentMethods]}>
          {children}
      </PaymentMethodContext.Provider>
  );
}

export const usePaymentMethodContext = () => useContext(PaymentMethodContext);


import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Attribute } from '../../../models/attribute/attribute';
import { ResourceOfFindContext } from '../../../contexts/attribute/sourceOfFind/sourceOfFindContext';

export const ResourcesOfFindProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [resources_of_find, setResourcesOfFind] = useState<Attribute[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getResourcesOfFinds() {
        try {
          const { data } = await axios.get('attributes/15');
          if (isMounted) {
            setResourcesOfFind(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      getResourcesOfFinds();
  
      return () => {
        isMounted = false;
      };
  }, []);


  return (
      <ResourceOfFindContext.Provider value={[resources_of_find, setResourcesOfFind]}>
          {children}
      </ResourceOfFindContext.Provider>
  );
}

export const useResourcesOfFindContext = () => useContext(ResourceOfFindContext);

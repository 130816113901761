import axios from "axios";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Spin, Tag } from 'antd';

import Mailto from "../../link/mailto";
import Tel from "../../link/tel";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

const LeaseAgreementDetail = ({ id }: any) => {
  const [lease_agreement, setLeaseAgreement] = useState<any | null>(null);

  const calculateDateDifference = (end_date) => {
    const today = moment();
    const endDate = moment(end_date, "DD.MM.YYYY");
    const differenceInDays = endDate.diff(today, "days");
    return differenceInDays;
  };
  useEffect(() => {
    (
      async () => {
        if (id > 0) {
          const { data } = await axios.get(`contract/lease-agreements/${id}`);
          setLeaseAgreement(data);
        }
      }
    )()
  }, [id]);

  const dateDifference = calculateDateDifference(lease_agreement?.end_date);

  let color = 'green';
  if (dateDifference < 0) {
    color = 'red';
  }
  if (dateDifference < 11 && dateDifference > 0) {
    color = 'lime';
  }

  if (!lease_agreement) {
    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-5 mb-5">
          <div className="d-flex justify-content-between bd-highlight mb-3">
            <Spin></Spin>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row">

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>KİRALAYAN BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{lease_agreement?.seller?.customer_type?.name}</th>
            </tr>
            {lease_agreement?.seller?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{lease_agreement?.seller?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{lease_agreement?.seller?.tax_admin} / {lease_agreement?.seller?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{lease_agreement?.seller?.first_name} {lease_agreement?.seller?.last_name}</td>
            </tr>
            <tr>
              <th>{lease_agreement?.seller?.id_type?.name}</th>
              <td>{lease_agreement?.seller?.id_no}</td>
            </tr>
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${lease_agreement?.seller?.phoneCode?.phone_code} ${lease_agreement?.seller?.phone}`}>+{lease_agreement?.seller?.phoneCode?.phone_code} {lease_agreement?.seller?.phone}</Tel></td>
            </tr>
            {lease_agreement?.seller?.email != '0' ?
              <tr>
                <th scope="row">EMAİL</th>
                <td><Mailto email={lease_agreement?.seller?.email} body="Merhaba">{lease_agreement?.seller?.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{lease_agreement?.seller?.birthday}</td>
            </tr>
            <tr>
              <th scope="row">ADRES</th>
              <td>{lease_agreement?.seller?.address}</td>
            </tr>
            {lease_agreement.seller?.definition != '0' ?

              <tr>
                <th scope="row">NOT</th>
                <td>{lease_agreement?.seller?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>KİRACI BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{lease_agreement?.buyer?.customer_type?.name}</th>
            </tr>
            {lease_agreement?.buyer?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{lease_agreement?.buyer?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{lease_agreement?.buyer?.tax_admin} / {lease_agreement?.buyer?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{lease_agreement?.buyer?.first_name} {lease_agreement?.buyer?.last_name}</td>
            </tr>
            <tr>
              <th>{lease_agreement?.buyer?.id_type?.name}</th>
              <td>{lease_agreement?.buyer?.id_no}</td>
            </tr>
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${lease_agreement?.buyer?.phoneCode?.phone_code} ${lease_agreement?.buyer?.phone}`}>+{lease_agreement?.buyer?.phoneCode?.phone_code} {lease_agreement?.buyer?.phone}</Tel></td>
            </tr>
            {lease_agreement?.buyer?.email != '0' ?
              <tr>
                <th scope="row">EMAİL</th>
                <td><Mailto email={lease_agreement?.buyer?.email} body="Merhaba">{lease_agreement?.buyer?.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{lease_agreement?.buyer?.birthday}</td>
            </tr>
            <tr>
              <th scope="row">ADRES</th>
              <td>{lease_agreement?.buyer?.address}</td>
            </tr>
            {lease_agreement.buyer?.definition != '0' ?

              <tr>
                <th scope="row">NOT</th>
                <td>{lease_agreement?.buyer?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
      {lease_agreement.guarantor?.id >0 ?
              <>
                <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>KEFİL BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{lease_agreement?.guarantor?.customer_type?.name}</th>
            </tr>
            {lease_agreement?.guarantor?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{lease_agreement?.guarantor?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{lease_agreement?.guarantor?.tax_admin} / {lease_agreement?.guarantor?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{lease_agreement?.guarantor?.first_name} {lease_agreement?.guarantor?.last_name}</td>
            </tr>
            <tr>
              <th>{lease_agreement?.guarantor?.id_type?.name}</th>
              <td>{lease_agreement?.guarantor?.id_no}</td>
            </tr>
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${lease_agreement?.guarantor?.phoneCode?.phone_code} ${lease_agreement?.guarantor?.phone}`}>+{lease_agreement?.guarantor?.phoneCode?.phone_code} {lease_agreement?.guarantor?.phone}</Tel></td>
            </tr>
            {lease_agreement?.guarantor?.email != '0' ?
              <tr>
                <th scope="row">EMAİL</th>
                <td><Mailto email={lease_agreement?.guarantor?.email} body="Merhaba">{lease_agreement?.guarantor?.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{lease_agreement?.guarantor?.birthday}</td>
            </tr>
            <tr>
              <th scope="row">ADRES</th>
              <td>{lease_agreement?.guarantor?.address}</td>
            </tr>
            {lease_agreement.guarantor?.definition != '0' ?

              <tr>
                <th scope="row">NOT</th>
                <td>{lease_agreement?.guarantor?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
              </>
              : null}
      <div className="clearfix border-bottom border-light mb-3"></div>
      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>MÜLK BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>GAYRİMENKUL CİNSİ</th>
              <th>{lease_agreement.property?.property_genus?.name}</th>
            </tr>
            {lease_agreement.property?.property_genus?.id !== 10 ?
              <>
                <tr>
                  <th>GAYRİMENKUL TÜRÜ</th>
                  <td>{lease_agreement.property?.property_type && lease_agreement.property?.property_type.name}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>ADA</th>
              <td>{lease_agreement.property?.bob}</td>
            </tr>
            <tr>
              <th>PARSEL</th>
              <td>{lease_agreement.property?.parcel}</td>
            </tr>
            <tr>
              <th>İL</th>
              <td>{lease_agreement.property?.province.name}</td>
            </tr>
            <tr>
              <th>İLÇE</th>
              <td>{lease_agreement.property?.district?.name}</td>
            </tr>
            <tr>
              <th>MAHALLE</th>
              <td>{lease_agreement.property?.neighbourhood?.name}</td>
            </tr>
            <tr>
              <th>TAM ADRES</th>
              <td>{lease_agreement.property?.address}</td>
            </tr>
            {lease_agreement.property_genus_id === 8 || lease_agreement.property_genus_id === 10 ? null :
              <>
                <tr>
                  <th>BULUNDUĞU KAT</th>
                  <td>{lease_agreement.property?.floor_location?.name}</td>
                </tr>
              </>
            }
            <tr>
              <th>BİNA YAŞI</th>
              <td>{lease_agreement.property?.build_date}</td>
            </tr>
            <tr>
              <th>BRÜT m<sup>2</sup></th>
              <td>{lease_agreement.property?.brut}</td>
            </tr>
            {lease_agreement.property_genus_id === 8 || lease_agreement.property_genus_id === 10 ? null :
              <>
                <tr>
                  <th>NET m<sup>2</sup></th>
                  <td>{lease_agreement.property?.net}</td>
                </tr>
                <tr>
                  <th>YAPI TÜRÜ</th>
                  <td>{lease_agreement.property?.build_type?.name}</td>
                </tr>
                {lease_agreement.property?.build_type?.id === 115 ?
                  <>
                    <tr>
                      <th>SİTE</th>
                      <td>{lease_agreement.property?.site?.name}</td>
                    </tr>
                  </>
                  : null}
              </>
            }
            {lease_agreement.property?.definition != '0' && lease_agreement.property?.definition != null ?
              <tr>
                <th scope="row">NOT</th>
                <td>{lease_agreement.property?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>SÖZLEŞME BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>BAŞLANGIÇ TARİHİ</th>
              <th>{moment(lease_agreement.start_date).format('DD/MM/YYYY')}</th>
            </tr>
            <tr>
              <th>BİTİŞ TARİHİ</th>
              <td>{moment(lease_agreement.end_date).format('DD/MM/YYYY')}</td>
            </tr>
            <tr>
              <th>KALAN GÜN SAYISI</th>
              <td><Tag color={color}>{moment(lease_agreement.end_date).diff(moment(), 'days')}</Tag></td>

            </tr>
            <tr>
              <th scope="row">Sözleşme Süresi</th>
              <td><strong className="text-danger">{lease_agreement.lease_time}</strong></td>
            </tr>
            <tr>
              <th>AYLIK KİRA BEDELİ</th>
              <td>  <strong>
                <NumericFormat
                  value={lease_agreement.per_month_price.toString().replace('.', ',')}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                /> TL
              </strong></td>
            </tr>
            <tr>
              <th>YILLIK KİRA BEDELİ</th>
              <td>  <strong>
                <NumericFormat
                  value={lease_agreement.per_year_price.toString().replace('.', ',')}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                /> TL
              </strong></td>
            </tr>
            <tr>
              <th>DEPOZİTO TUTARI</th>
              <td>  <strong>
                <NumericFormat
                  value={lease_agreement.deposit.toString().replace('.', ',')}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                /> TL
              </strong></td>
            </tr>
            <tr>
              <th>ÖDEME ŞEKLİ</th>
              <td>{lease_agreement.payment_type}</td>
            </tr>
            <tr>
              <th scope="row">DURUM</th>
              <td><Tag color={lease_agreement.status?.color}>{lease_agreement.status?.name}</Tag></td>
            </tr>

          </tbody>
        </table>
      </div>
      <div className="clearfix border-bottom border-light mb-3"></div>
      <div className="col-12">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>SÖZLEŞME MADDELERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan={2} dangerouslySetInnerHTML={{ __html: lease_agreement.content }} />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LeaseAgreementDetail;


import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Swal from 'sweetalert2';
import { DatePicker, Select, Table, Tag, Radio, Segmented, Tabs, TabsProps, Rate } from 'antd';
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { useUserContext } from "../../../providers/user/userProvider";
import { Category } from "../../../models/user/category";
import { User } from "../../../models/user";
import { useStatusContext } from "../../../providers/attribute/status/statusProvider";
import { Attribute } from "../../../models/attribute/attribute";
import { FiTrash } from 'react-icons/fi';

//Table
import type { ColumnsType, TableProps } from 'antd/es/table';
import { NumericFormat } from "react-number-format";
import { ThumbsDown } from "react-feather";
import Breadcrumb from "../../../components/constant/breadcrumb";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import { Customer } from "../../../models/accounting/customer/customer";
import ProfilePhoto from "../../../components/user/profilePhoto";
import SatisfactionSurveyDetail from "../../../components/customer/satisfaction-survey/detail";

const { Option } = Select;


dayjs.locale("tr");
const { RangePicker } = DatePicker;
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}


//Table
interface DataType {
    last_name: any;
    first_name: any;
    key: React.Key;
    id(id: any): unknown;
    month: any;
    created_at: string;
    customer: Customer;
    category: string;
    avg_rate: any;
    agent: User;
}

const SatisfactionSurvey = () => {

    //Table 
    const [data, setData] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState<any | null>(null);
    const [pagination, setPagination] = useState<any | null>({
        current: 1,
        pageSize: 100,
        currentPageSize: 100,
        total: reports?.total,
        pageSizeOptions: [10, 20, 50, 100, 500, 100],
        showQuickJumper: true,
        showSizeChanger: true,
        showLessItems: true,
        responsive: true
    });
    const [filter_url, setFilterUrl] = useState<any | null>('');
    const [sort, setSort] = useState(false);
    const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=desc');
    const [sort_req, setSortReq] = useState<any | null>([]);
    const [active_tab_id, setActiveTabId] = useState(1);
    const [score_names, setScoreNames] = useState<any>();

    //Modal
    const [show_id, setShowID] = useState<any | null>(0);
    const [show_full_name, setShowFullName] = useState<any | null>('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    //For filter
    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [send_filter, setSendFilter] = useState(false);
    const [category_id, setCategoryId] = useState<any | null>(null);
    const [status_id, setStatusId] = useState<any | null>(null);
    const [filter, setFilter] = useState(false);
    const [clear_filter_inputs, setClearFilterInputs] = useState(false);

    const [users, setUsers] = useState([]);
    const [statuses, setStatuses] = useStatusContext();
    const [user_categories, setUserCategories] = useState([]);
    const [selected_agents, setSelectedAgents] = useState([]);
    const [transaction_types, setTransactionTypes] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [redirect_id, setRedirectId] = useState(0);



    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: `Genel`,
        },
        {
            key: '2',
            label: `İşlem Türü Bazlı`,
        },
        {
            key: '3',
            label: `Soru Bazlı`,
        },
    ];

    const tabOnChange = (key: number | any) => {
        setActiveTabId(key);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: '#',
            dataIndex: 'id',
            sorter: false,
            render: (id) => {
                return (
                    <>
                        {`${id}`}
                    </>
                );
            },
        },
        {
            title: 'Gayrimenkul Danışmanı',
            dataIndex: 'agent',
            sorter: false,
            render: (agent, record) => {
                return (
                    <>

                        <a onClick={() => { setShow(true); setShowID(record.id); setShowFullName(`${agent.first_name} ${agent.last_name}  -  ${record.customer?.first_name} ${record.customer?.last_name}`); }}>
                            {`${agent?.first_name} ${agent?.last_name}`}
                        </a>
                    </>
                );
            },
        },
        {
            title: 'Müşteri Adı Soyadı',
            dataIndex: 'customer',
            sorter: false,
            render: (customer) => {
                return (
                    <>
                        {`${customer?.first_name} ${customer?.last_name}`}
                    </>
                );
            },
        },
        {
            title: 'Yorum Ortalaması',
            dataIndex: 'avg_rate',
            sorter: false,
            render: (avg_rate) => {
                return (
                    avg_rate && score_names ? (
                        <>
                            <Rate allowHalf disabled tooltips={score_names} value={Math.round(avg_rate)} />
                            <span className="ant-rate-text">
                                {score_names[Math.round(avg_rate) - 1]}
                            </span>
                            <strong className="ms-2">
                                <NumericFormat
                                    value={avg_rate?.toString().replace('.', ',')}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    displayType="text"
                                />
                            </strong>
                        </>
                    ) : null
                );
            },
        },
        {
            title: 'Ay',
            key: 'created_at',
            dataIndex: 'created_at',
            responsive: ["md", "lg", "xl", "xxl"],
            render: (created_at) => (
                <>
                    <Tag className={`label label-${moment(created_at, "DD/MM/YYYY").format('MM')}`}>{moment(created_at, "DD/MM/YYYY").format('MMMM')}</Tag>
                </>
            ),

        },
        {
            title: 'Yorum Tarihi',
            dataIndex: 'created_at',
            sorter: true
        },
        {
            render: (record) => {
                return (
                    <>
                        <a onClick={() => del(record.id)}
                            className="ms-2"
                        >
                            <FiTrash className="text-danger" />
                        </a>
                    </>
                );
            },
        }
    ];

    useEffect(() => {
        let fUrl = '';
        if (parseInt(status_id) > 0) {
            fUrl = `&filter_status_id=${status_id}`;
        }

        if (selected_agents?.length > 0) {
            selected_agents.forEach((agent_id: number) => {
                fUrl = `${fUrl}&filter_agent_id[]=${agent_id}`;
            });
        }

        if (category_id > 0) {
            fUrl = `${fUrl}&filter_user_category=${category_id}`;
        }

        setFilterUrl(fUrl);
        setFilter(false);
        setSendFilter(true);
    }, [filter]);

    useEffect(() => {
        (
            async () => {
                if (send_filter) {
                    setLoading(true);

                    axios.get(`customer/satisfaction-survey?limit=${pagination.pageSize}&page=${pagination.current}${sort_url}&filter_between[]=${start_date}&filter_between[]=${end_date}${filter_url}&filter_type=149`)
                        .then(({ data }) => {
                            setData(data.satisfaction_survey.data);
                            setReports(data.satisfaction_survey.report);
                            setPagination({
                                current: pagination.current,
                                pageSize: pagination.pageSize,
                                currentPageSize: pagination.currentPageSize,
                                total: data.satisfaction_survey.report?.total
                            });
                            setLoading(false);
                        });
                    setSendFilter(false);
                }
            }
        )()
    }, [send_filter]);

    useEffect(() => {
        if (clear_filter_inputs) {
            setSelectedAgents([]);
            setDateRange([]);
            setCategoryId(null);
            setStatusId(null);
            setClearFilterInputs(false);
            setFilter(true);
        }
    }, [clear_filter_inputs]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('user/user-categories');
                setUserCategories(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`attributes/46`);
                const updatedDesc = data?.data?.map((item: Attribute) => item.name);
                setScoreNames(updatedDesc);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/43');
                setTransactionTypes(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/45');
                setQuestions(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                if (category_id > 0) {
                    const { data } = await axios.get(`users/by-category/${category_id}`);
                    setUsers(data);
                } else {
                    const { data } = await axios.get('users');
                    setUsers(data.data);
                }
            }

        )()
    }, [category_id]);



    const [size, setSize] = useState<any>('large'); // default is 'middle'


    const del = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Kalıcı olarak silinecektir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`customer/satisfaction-survey/${id}`);
                setData(data.filter((u: any) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    if (redirect_id > 0) {
        return <Navigate to={`/accounting/table-revenues/${redirect_id}/edit`} />;
    }

    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Müşteri Yorumları', active: true },
    ];

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Müşteri Yorumları</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 mb-3">
                                    <div className="form-group select-style">
                                        <label className="d-block">Tarih Aralığı</label>
                                        <RangePicker
                                            format={'DD/MM/YYYY'}
                                            locale={locale}
                                            ranges={rangePresets}
                                            value={date_range}
                                            style={{ width: '100%' }}
                                            onChange={(value, dateString) => {
                                                setDateRange(value);
                                                setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setSendFilter(true);
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>Kategori</label>
                                        <Select className="form-control" defaultValue={category_id} showSearch
                                            value={category_id}
                                            allowClear={true}
                                            placeholder="Kategori Seçiniz"
                                            onChange={e => { setCategoryId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                        >
                                            {user_categories.map((user_categories: Category) => {
                                                return (
                                                    <Option value={user_categories.id} title={user_categories.name}
                                                        key={user_categories.name}
                                                        id={user_categories.name}>{user_categories.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group mb-3 select-style">
                                        <label>Danışman (lar)</label>
                                        <Select className="form-control" showSearch
                                            allowClear={true}
                                            onChange={e => { setSelectedAgents(e); setFilter(true); }}
                                            value={selected_agents}
                                            defaultValue={selected_agents}
                                            mode="multiple"
                                            placeholder="Danışman Seçiniz"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {users.map((users: User) => {
                                                return (
                                                    <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                        key={users.first_name + ' ' + users.last_name}
                                                        id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="float-start">
                                    <button type="button" onClick={e => setClearFilterInputs(true)} className="btn btn-soft-light btn-sm" >Temizle</button>
                                </div>
                                <div className="float-end">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Tabs defaultActiveKey="1" items={tabItems} onChange={tabOnChange} />
                            <div className={active_tab_id == 1 ? 'table-responsive' : 'table-responsive d-none'}>
                                <table className="table mb-0 table-bordered table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Toplam Yorum</th>
                                            <th>Puan Ortalaması</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row" className="pt-3">
                                                <strong>
                                                    <NumericFormat
                                                        value={reports?.total?.toString().replace('.', ',')}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        displayType="text"
                                                    />
                                                </strong>
                                            </td>
                                            <td>
                                                {reports?.round_avg_rate && score_names ? (
                                                    <>
                                                        <Rate allowHalf disabled tooltips={score_names} value={reports?.round_avg_rate} />
                                                        <span className="ant-rate-text">
                                                            {score_names[reports?.round_avg_rate - 1]}
                                                        </span>
                                                    </>
                                                ) : null}

                                                <strong className="m-3">
                                                    <NumericFormat
                                                        value={reports?.avg_rate?.toString().replace('.', ',')}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        displayType="text"
                                                    />
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={active_tab_id == 2 ? 'table-responsive' : 'table-responsive d-none'}>
                                <table className="table mb-0 table-bordered table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th>İşlem Türü</th>
                                            <th>Puan Ortalaması</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reports?.transaction_type_avg_rates?.map((transactionTypes: any) => {
                                            return (
                                                <tr>
                                                    <td scope="row" className="pt-3">
                                                        {transactionTypes.name}
                                                    </td>
                                                    <td>
                                                        <Rate allowHalf disabled tooltips={score_names} value={Math.round(transactionTypes?.avg_rate)} />
                                                        <span className="ant-rate-text">
                                                            {score_names[Math.round(transactionTypes?.avg_rate) - 1]}
                                                        </span>
                                                        <strong className="ms-2">
                                                            <NumericFormat
                                                                value={transactionTypes?.avg_rate?.toString().replace('.', ',')}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                displayType="text"
                                                            />
                                                        </strong>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            <div className={active_tab_id == 3 ? 'table-responsive' : 'table-responsive d-none'}>
                                <table className="table mb-0 table-bordered table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Soru</th>
                                            <th>Puan Ortalaması</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reports?.question_avg_rates?.map((questions: any) => {
                                            return (
                                                <tr>
                                                    <td scope="row" className="pt-3">
                                                        {questions.name}
                                                    </td>
                                                    <td>
                                                        <Rate allowHalf disabled tooltips={score_names} value={Math.round(questions?.avg_rate)} />
                                                        <span className="ant-rate-text">
                                                            {score_names[Math.round(questions?.avg_rate) - 1]}
                                                        </span>
                                                        <strong className="ms-2">
                                                            <NumericFormat
                                                                value={questions?.avg_rate?.toString().replace('.', ',')}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                displayType="text"
                                                            />
                                                        </strong>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Table
                                className="table table-striped"
                                size="small"
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                scroll={{ y: '100%', x: true }}
                                rowKey={(record) => `${record.id}`}
                                pagination={pagination}
                                onChange={(val: any, filter: any, sorter: any, extra: any) => {
                                    setPagination({
                                        current: val.current,
                                        pageSize: val.pageSize,
                                        currentPageSize: val.currentPageSize
                                    });
                                    setSortReq(sorter);
                                    setSort(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="xl" animation={false}>
                {show_id && show_id !== null && show_id !== undefined &&
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SatisfactionSurveyDetail id={show_id} />
                        </Modal.Body><Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Kapat
                            </Button>
                        </Modal.Footer>
                    </>
                }
            </Modal>
        </Wrapper>
    )
}

export default SatisfactionSurvey;
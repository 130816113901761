import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";

import { Result } from "../../../models/attribute/result/result";
import { Communication } from "../../../models/attribute/communication/communication";
import { User } from "../../../models/user";

import  isSelectOption  from '../../../components/helper/selectSetting';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import Select, { components } from "react-select";
import tr from 'date-fns/locale/tr';
import AsyncCreatableSelect from 'react-select/async-creatable';

import "react-datepicker/dist/react-datepicker.css";


const Create = () => {
    registerLocale('tr', tr);
    setDefaultLocale('tr');
    
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [note, setNote] = useState('');

    const [result_id, setResultId] = useState('');    
    const [communication_id, setCommunicationId] = useState('');
    const [redirect_id, setRedirectId] = useState('');
    const [calls, setCalls] = useState([]);
    const [redirect, setRedirect] = useState(false);
    
    const [results, setResults] = useState([]);
    const [communications, setCommunications] = useState([]);
    const [users, setUsers] = useState([]);
    
    const [meet_date_time, setCallDateTime] = useState(new Date());

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get('attributes/1');

                setResults(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get('attributes/2');

                setCommunications(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get('users');

                setUsers(data.data);
            }
        )()
    }, []);



    function withEvent(func: Function): React.ChangeEventHandler<any> {
        return (event: React.ChangeEvent<any>) => {
          const { target } = event;
          func(target.value);
        };
      }
      
    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('asistant/calls');

                setCalls(data);
            }
        )()
    }, []);

    var resOpt=[] as any;
    var resArrOpt = [] as any;

    {results.map((r: Result) => {
        resOpt['value']=r.id;
        resOpt['label']=r.name;
        resArrOpt.push({...resOpt});
    })}

    var commOpt=[] as any;
    var commArrOpt = [] as any;

    {communications.map((c: Communication) => {
        commOpt['value']=c.id;
        commOpt['label']=c.name;
        commArrOpt.push({...commOpt});
    })}
    
    var redrOpt=[] as any;
    var redrArrOpt = [] as any;

    {users.map((r: User) => {
        redrOpt['value']=r.id;
        redrOpt['label']=r.name;
        redrArrOpt.push({...redrOpt});
    })}

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        await axios.post('asistant/calls', {
            first_name,
            last_name,
            email,
            phone,
            address,
            note,
            result_id,
            communication_id,
            meet_date_time
        });

        setRedirect(true);
    }

    if (redirect) {
        return <Navigate to={'/asistant/calls'} />;
    }

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Görüşme Ekle</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to={'/asistant/calls'}>Görüşmeler</NavLink></li>
                                <li className="breadcrumb-item active">Oluştur</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={submit}>
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    onChange={e => setFirstName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    onChange={e => setLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" required data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir telefon numarası giriniz."
                                                    className="form-control"
                                                    onChange={e => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                      
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Görüşme Sonucu</label>
                                                <AsyncCreatableSelect
                                                    isClearable={false}
                                                    loadOptions={async inputValue =>results}
                                                    onInputChange={async inputValue =>results}
                                                    placeholder={'Seçiniz...'}
                                                    defaultOptions={resArrOpt}    
                                                    isSearchable={false}                                              
                                                    onChange={async (e: any) => {     
                                                        if (e.__isNew__) {
                                                            let name = e.value;
                                                            let type = '1';
                                                            const attrData = await axios.post('attribute', {
                                                                name,
                                                                type
                                                            }
                                                            );
                                                            if(attrData.data.success){ setResultId(attrData.data.data); }
                                                        }else{
                                                            setResultId(e.value);
                                                        }
                                                    }}
                                                />                                                 
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>İletişim Kaynağı</label>
                                                <AsyncCreatableSelect
                                                    isClearable={false}
                                                    loadOptions={async inputValue =>communications}
                                                    onInputChange={async inputValue =>communications}
                                                    placeholder={'Seçiniz...'}
                                                    defaultOptions={commArrOpt}    
                                                    isSearchable={false}                                              
                                                    onChange={async (e: any) => {     
                                                        if (e.__isNew__) {
                                                            let name = e.value;
                                                            let type = '2';
                                                            const attrData = await axios.post('attribute', {
                                                                name,
                                                                type
                                                            }
                                                            );
                                                            if(attrData.data.success){ setCommunicationId(attrData.data.data); }
                                                        }else{
                                                            setCommunicationId(e.value);
                                                        }
                                                    }}
                                                /> 
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Yönlendirilen</label>
                                                <AsyncCreatableSelect
                                                    isClearable={false}
                                                    loadOptions={async inputValue =>users}
                                                    onInputChange={async inputValue =>users}
                                                    placeholder={'Seçiniz...'}
                                                    defaultOptions={redrArrOpt}    
                                                    isSearchable={false}                                              
                                                    onChange={async (e: any) => {                                                          
                                                        setRedirectId(e.value);                                                      
                                                    }}
                                                />     
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Görüşme Tarih / Saati</label>
                                                <DatePicker
                                                className="form-control"
                                                selected={meet_date_time}
                                                onChange={(date:any) => setCallDateTime(date)}
                                                showTimeSelect
                                                timeFormat="p"
                                                locale="tr"
                                                timeIntervals={15}
                                                dateFormat="Pp"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" required data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    onChange={e => setAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea  
                                                    className="form-control"
                                                    onChange={e => setNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Kaydet</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Create;
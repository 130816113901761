import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Paginator from "../../../components/paginator";
import Mailto from "../../../components/link/mailto";
import Tel from "../../../components/link/tel";
import { Call } from "../../../models/asistant/call/call";
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";


const Calls = () => {

    const [callLists, setCallLists] = useState<any|null>([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);

    //Detail Modal
    const [full_name, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [note, setNote] = useState('');
    const [result, setResult] = useState('');
    const [communication, setCommunication] = useState(null);
    const [callMeta, setCallMeta] = useState([]);
    const [callID, setCallID] = useState('');

    //Modal add calls
    const [name, setName] = useState('');
    const [definition, setDefinition] = useState('');
    const [call_date_time, setCallDateTime] = useState(new Date());
    // const [metas, setCallCount] = useState([0,1]);

    // setCallCount((metas) => metas.filter((_, index) => index !== 0));

      const [metas, setCallCount] = useState([0]);
      const handleRemoveItem = (item:any) => {
       setCallCount(metas.filter(item => item !== item));
      };

    //Modal    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id: number) => {
        (
            async () => {
                setShow(true);
                const calls = await axios.get(`asistant/calls/${id}`);
                setCallID(callLists.data.id);
                setFullName(callLists.data.first_name + " " + callLists.data.last_name);
                setPhone(callLists.data.phone);
                setEmail(callLists.data.email);
                setAddress(callLists.data.address);
                setNote(callLists.data.note);
                setResult(callLists.data.result.name);
                setCommunication(callLists.data.communication.name);
                setCallMeta(callLists.data.meta);
            }
        )()
    };

    const modalLoaded = () => {
    };

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`asistant/calls?page=${page}`);

                setCallLists(data);
                setLastPage(data.meta.last_page);
            }
        )()
    }, [page]);

    const del = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Kalıcı olarak silinecektir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`asistant/calls/${id}`);

                setCallLists(callLists.filter((u: Call) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }


    return (
        <Wrapper>
            <Modal show={show} onHide={handleClose} onEntered={modalLoaded} size="lg" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Görüşme Detayları - {full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Ad Soyad</th>
                                <th>{full_name}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Telefon</th>
                                <td><Tel phone={phone}>{phone}</Tel></td>
                            </tr>
                            <tr>
                                <th scope="row">Email</th>
                                <td><Mailto email={email} body="Merhaba">{email}</Mailto></td>
                            </tr>
                            <tr>
                                <th scope="row">Adres</th>
                                <td>{address}</td>
                            </tr>
                            <tr>
                                <th scope="row">İletişim Kaynağı</th>
                                <td>{communication}</td>
                            </tr>
                            <tr>
                                <th scope="row">Durum</th>
                                <td><span className="badge bg-danger">{result}</span></td>
                            </tr>
                            <tr>
                                <th scope="row">Not</th>
                                <td>{note}</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Kapat
                    </Button>
                    <Link className="btn btn-primary" to={`/asistant/calls/${callID}/edit`}>
                        Güncelle
                    </Link>
                </Modal.Footer>
            </Modal>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Görüşmeler</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item active">Görüşmeler</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <h5 className="card-title">Görüşmeler</h5>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <Link to="/asistant/calls/create" className="btn btn-light"><i className="bx bx-plus me-1"></i> Yeni Ekle</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Ad Soyad</th>
                                            <th>Telefon</th>
                                            <th>Email</th>
                                            <th>Ekleyen Personel</th>
                                            <th>Durum</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {callLists.map((callLists: Call) => {
                                            return (
                                                <tr key={callLists.id}>
                                                    <td>{callLists.id}</td>
                                                    <td onClick={() => handleShow(callLists.id)}>{callLists.first_name} {callLists.last_name}</td>
                                                    <td><Tel tel={callLists.phone}>{callLists.phone}</Tel></td>
                                                    <td><Mailto email={callLists.email} body="Merhaba">{callLists.email}</Mailto></td>
                                                    <td>{callLists.user.first_name} {callLists.user.last_name}</td>
                                                    <td>{callLists.result.name}</td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                                            </button>

                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                <li>
                                                                    <Link to={`/asistant/calls/${callLists.id}/edit`}
                                                                        className="dropdown-item">Güncelle</Link>
                                                                </li>
                                                                <li><a className="dropdown-item" href="#" onClick={() => del(callLists.id)}>Sil</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Paginator page={page} lastPage={lastPage} pageChanged={setPage} />

                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}

export default Calls;
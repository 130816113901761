
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BuildType } from '../../../models/attribute/buildType/buildType';
import { BuildTypeContext } from '../../../contexts/attribute/buildType/buildTypeContext';

export const BuildTypeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [build_types, setBuildTypes] = useState<BuildType[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getDatas() {
        try {
          const { data } = await axios.get('attributes/10');
          if (isMounted) {
            setBuildTypes(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      getDatas();
  
      return () => {
        isMounted = false;
      };
  }, []);


  return (
      <BuildTypeContext.Provider value={[build_types, setBuildTypes]}>
          {children}
      </BuildTypeContext.Provider>
  );
}

export const useBuildTypeContext = () => useContext(BuildTypeContext);

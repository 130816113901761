
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { User } from '../../models/user';
import { UserContext } from '../../contexts/user/userContext';


export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getUsers() {
        try {
          const { data } = await axios.get('users');
          if (isMounted) {
            setUsers(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
    
      setTimeout(() => {
        getUsers();
      }, 500);
    
      return () => {
        isMounted = false;
      };
  }, []);


  return (
      <UserContext.Provider value={[users, setUsers]}>
          {children}
      </UserContext.Provider>
  );
}

export const useUserContext = () => useContext(UserContext);

const ProfilePhoto = ({ id, imgHeight, imgClass, imgAlt }: any) => {
  return (
    <>
      <img src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" height={imgHeight} className={imgClass} alt={imgAlt} />
    </>
  );
}

export default ProfilePhoto;

